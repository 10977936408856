import { success } from 'redux-saga-requests'
import {
  all,
  delay,
  put,
  putResolve,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'

import { RemoteFilterType } from './types'
import { FETCH_OFFERS, FETCH_API, SHOW_MORE_OFFERS } from './constants'
import {
  fetchSourceAction,
  FetchOffersActionType,
  fetchApiAction,
  FetchApiActionType,
  ShowMoreOffersActionType,
} from './actions'

import {
  FETCH_USER,
  CLEAR_USER,
} from '@monorepo/deprecated/core/features/user/constants'
import {
  fetchOffersAction,
  successFetchOffersAction,
  errorFetchOffersAction,
  successFetchApiAction,
} from './actions'
import { resultSelector } from './selectors'
import { StateType } from './records'
import { createKey } from './utils'
// import { userSelector } from '../../features/user'

// function* watchFetchAllOffers() {
//   const { query } = yield select(getLocation)
//   const filter = pick(query, 'brandName', 'number')

//   const { loading: isUserLoading } = yield select(userSelector)

//   if (!isUserLoading) {
//     try {
//       yield all([
//         putResolve(fetchOffers({ ...filter, crosses: true, stockOnly: true })),
//         putResolve(fetchOffers({ ...filter, crosses: true, stockOnly: false })),
//       ])
//     } catch (error) {}

//     try {
//       yield call(watchFetchAllRemoteOffers, { ...filter, crosses: true })
//     } catch (error) {}
//   }
// }

// function* watchFetchAllRemoteOffers({ brandName, number, crosses }) {
//   const remoteVendors = yield select(remoteVendorsSelector)
//   const remote = remoteVendors.map(providerName =>
//     putResolve(fetchOffers({ brandName, number, crosses, providerName }))
//   )

//   yield all(remote)
// }

// // function* watchSuccessFetchOffers({ meta }) {}

function* watchChangeUser() {
  yield delay(500)
  const {
    data: { good },
  }: StateType = yield select(resultSelector)

  if (good) {
    yield put(
      fetchOffersAction({
        brandName: good?.brandName,
        number: good?.number,
        crosses: true,
      })
    )
  }
}

function* watchShowMoreOffers({ meta: { good } }: ShowMoreOffersActionType) {
  const {
    data: { apiState, requestParams },
  }: StateType = yield select(resultSelector)

  if (good && requestParams && !apiState[createKey(good)]) {
    yield put(
      fetchApiAction({
        ...requestParams,
        brandName: good.brandName,
        number: good.number,
      })
    )
  }
}

function* fetchSource(filter: RemoteFilterType) {
  try {
    yield putResolve(fetchSourceAction(filter))
  } catch (error) {}
}

function* watchFetchApi({ meta: { filter } }: FetchApiActionType) {
  const {
    data: { remote },
  }: StateType = yield select(resultSelector)

  if (remote.length > 0) {
    yield all(
      remote.map((providerName) => fetchSource({ ...filter, providerName }))
    )
  }
  yield put(successFetchApiAction(filter))
}

function* watchFetchOffers(action: FetchOffersActionType) {
  const filter = action.payload

  yield all([
    fetchSource({ ...filter, stockOnly: true }),
    fetchSource({ ...filter, stockOnly: false }),
  ])

  const state: StateType = yield select(resultSelector)

  if (state.data.good) {
    yield put(fetchApiAction(filter))
  }

  yield put(
    state.error && !state.data.good
      ? errorFetchOffersAction()
      : successFetchOffersAction()
  )
}

export function* searchSaga() {
  yield takeLatest(FETCH_OFFERS, watchFetchOffers)
  yield takeLatest(success(FETCH_USER), watchChangeUser)
  yield takeLatest(CLEAR_USER, watchChangeUser)
  yield takeEvery(FETCH_API, watchFetchApi)
  // // yield takeLatest(success(FETCH_OFFERS), watchSuccessFetchOffers)
  yield takeEvery(SHOW_MORE_OFFERS, watchShowMoreOffers)
}
