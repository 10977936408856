import React from 'react'

import { Link } from 'App/Components/Link'

type LogoProps = {
  logoImage: string
}

const logoStyle = { maxWidth: 200, maxHeight: 70 }

export const Logo: React.FC<LogoProps> = ({ logoImage }) => {
  return (
    <div className="header__logo">
      <Link to="/" className="logo">
        <div className="logo__slogan">
          Интернет-магазин автозапчастей и автосервис
        </div>
        <div className="logo__image">
          <img src={logoImage} style={logoStyle} alt="" />
        </div>
      </Link>
    </div>
  )
}

// import React from 'react'

// import { Link } from 'App/Components/Link'

// type LogoProps = {
//   logoImage: string
// }

// export const Logo: React.FC<LogoProps> = () => (
//   <div className="header__logo">
//     <Link to="/" className="logo">
//       <div className="logo__slogan">
//         Интернет-магазин автозапчастей и автосервис
//       </div>
//       <div className="logo__image">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           height="143.000000pt"
//           width="1788.000000pt"
//         >
//           <g transform="translate(0.000000,143.000000) scale(0.100000,-0.100000)">
//             <path
//               className="logo__part-primary"
//               d="M59 1343 c-10 -11 -14 -44 -14 -104 0 -129 -10 -123 240 -127 l205 -3 0 -513 c0 -465 2 -515 17 -527 11 -9 51 -15 124 -17 105 -4 109 -3 134 22 l25 25 0 506 0 505 191 0 c185 0 222 5 241 34 4 6 8 50 8 97 0 71 -3 89 -18 102 -17 15 -72 17 -579 17 -509 0 -562 -2 -574 -17z
//           M1386 1344 c-14 -14 -16 -85 -16 -638 0 -568 1 -625 17 -637 23 -20 905 -25 943 -6 24 12 25 18 28 109 2 77 0 99 -13 112 -14 14 -57 16 -346 16 l-329 0 0 150 0 149 280 3 c336 4 315 -5 315 127 0 60 -4 93 -14 104 -12 15 -45 17 -297 17 l-284 0 0 130 0 130 313 0 c231 0 318 4 337 13 24 12 25 17 28 109 2 77 0 99 -13 112 -15 14 -67 16 -475 16 -404 0 -460 -2 -474 -16z
//           M2537 1342 c-15 -17 -17 -75 -17 -635 0 -671 -3 -638 55 -653 43 -11 211 0 228 15 15 12 17 41 17 232 l0 219 185 0 c208 0 299 14 401 60 84 38 147 98 187 179 30 62 32 72 32 176 0 100 -3 116 -29 172 -52 114 -161 195 -314 234 -67 17 -112 19 -402 19 -304 0 -328 -1 -343 -18z m689 -256 c107 -50 136 -167 63 -251 -47 -53 -102 -65 -301 -65 l-168 0 0 170 0 170 178 0 c165 0 180 -2 228 -24z
//           M3809 1343 c-12 -13 -14 -125 -14 -634 0 -580 1 -619 18 -636 14 -15 38 -19 127 -21 106 -4 110 -3 135 22 l25 25 0 211 0 210 179 0 c210 0 303 15 406 63 123 59 195 156 216 291 27 171 -56 337 -208 419 -111 59 -172 67 -538 67 -297 0 -334 -2 -346 -17z m663 -243 c78 -22 128 -87 128 -167 0 -62 -27 -106 -82 -135 -39 -21 -59 -23 -230 -26 l-188 -4 0 171 0 171 168 0 c92 0 184 -5 204 -10z
//           M5096 1344 c-14 -14 -16 -85 -16 -638 0 -568 1 -625 17 -637 10 -9 49 -15 105 -18 156 -6 117 -41 444 400 l289 390 5 -383 5 -382 25 -13 c32 -16 203 -17 233 -2 12 7 25 17 29 23 4 6 8 288 8 627 0 584 -1 617 -18 632 -15 14 -41 17 -139 17 l-121 0 -288 -390 -289 -389 -3 371 c-2 328 -4 374 -19 390 -14 15 -33 18 -134 18 -94 0 -121 -3 -133 -16z
//           M6376 1338 c-11 -16 -16 -46 -16 -101 0 -69 3 -81 25 -102 24 -25 25 -25 220 -25 l195 0 0 -505 0 -506 25 -25 c25 -25 29 -26 134 -22 73 2 113 8 124 17 15 12 17 62 17 527 l0 514 200 0 c200 0 201 0 225 25 22 21 25 33 25 102 0 55 -5 85 -16 101 l-15 22 -564 0 -564 0 -15 -22z
//           M8075 1365 c-163 -36 -314 -143 -383 -273 -62 -117 -74 -172 -79 -353 -5 -146 -3 -176 16 -250 30 -120 75 -202 151 -279 115 -116 257 -170 445 -170 185 0 330 54 440 165 71 71 115 150 147 260 19 68 22 103 22 240 0 121 -4 176 -17 225 -75 279 -272 434 -567 446 -71 2 -128 -1 -175 -11z m291 -273 c65 -33 120 -100 143 -177 17 -55 21 -94 21 -210 0 -180 -18 -248 -85 -323 -60 -67 -113 -87 -225 -87 -103 0 -160 24 -220 92 -69 79 -84 134 -85 313 0 125 4 166 18 210 34 99 93 162 186 197 53 20 195 12 247 -15z
//           M9057 1342 c-15 -17 -17 -75 -17 -635 0 -671 -3 -638 55 -653 43 -11 211 0 228 15 15 12 17 41 17 232 l0 219 185 0 c210 0 302 14 402 60 84 39 146 99 186 179 30 62 32 72 32 176 0 100 -3 116 -29 172 -52 114 -161 195 -314 234 -67 17 -112 19 -402 19 -304 0 -328 -1 -343 -18z m689 -256 c107 -50 136 -167 63 -251 -47 -53 -102 -65 -301 -65 l-168 0 0 170 0 170 178 0 c165 0 180 -2 228 -24z
//           M10336 1338 c-14 -20 -16 -96 -16 -631 l0 -608 25 -24 c22 -23 31 -25 127 -25 74 0 109 4 124 15 12 8 147 184 300 391 l279 377 5 -371 c5 -339 7 -373 23 -389 15 -15 36 -18 132 -18 96 0 117 3 132 18 17 17 18 57 20 625 2 477 0 612 -10 632 -12 25 -14 25 -142 25 l-130 0 -285 -384 -285 -385 -5 371 c-4 293 -8 373 -19 386 -11 14 -34 17 -137 17 -116 0 -124 -1 -138 -22z
//           M12081 1345 c-247 -54 -390 -243 -351 -464 20 -113 70 -190 166 -253 40 -26 60 -46 55 -52 -33 -42 -272 -431 -277 -450 -4 -19 1 -32 20 -50 24 -25 31 -26 134 -26 90 0 114 3 131 18 12 9 81 114 154 232 l132 215 113 3 112 3 0 -213 c0 -117 4 -218 8 -224 16 -25 55 -34 142 -34 87 0 126 9 142 34 4 6 8 288 8 627 0 584 -1 617 -18 632 -16 15 -52 17 -313 16 -210 0 -312 -5 -358 -14z m389 -405 l0 -170 -137 0 c-153 0 -198 9 -243 47 -42 35 -60 73 -60 128 0 78 41 131 120 153 19 6 99 11 178 11 l142 1 0 -170z
//        "
//             />
//             <path
//               className="logo__part-secondary"
//               d="M13167 1342 c-15 -17 -17 -75 -17 -633 0 -338 4 -619 8 -625 16 -25 55 -34 142 -34 78 0 118 8 140 27 3 3 7 156 10 340 l5 336 154 -329 c89 -190 165 -339 179 -352 23 -22 32 -24 113 -20 62 2 93 8 103 18 8 8 85 167 173 353 l158 339 5 -343 5 -343 25 -13 c32 -16 203 -17 233 -2 12 7 25 17 29 23 4 6 8 288 8 627 0 584 -1 617 -18 632 -16 14 -43 17 -165 17 -141 0 -147 -1 -161 -22 -13 -20 -343 -723 -385 -821 l-16 -38 -194 418 c-107 230 -200 428 -208 441 -13 21 -19 22 -162 22 -131 0 -150 -2 -164 -18z
//           M14906 1338 c-14 -20 -16 -96 -16 -631 l0 -608 25 -25 c25 -25 29 -26 135 -22 89 2 113 6 127 21 17 16 18 44 17 355 l-1 337 24 -50 c13 -27 86 -183 162 -345 103 -219 144 -298 162 -307 38 -20 172 -17 198 5 12 9 93 170 181 356 88 187 161 341 163 342 1 1 1 -148 -1 -332 l-3 -333 26 -27 c25 -25 29 -26 134 -22 73 2 113 8 124 17 16 12 17 70 17 637 0 553 -2 624 -16 638 -13 13 -42 16 -163 16 -107 0 -151 -3 -158 -12 -5 -7 -96 -200 -202 -428 -106 -228 -196 -419 -200 -423 -4 -4 -95 179 -201 407 -106 229 -199 424 -207 436 -13 19 -25 20 -163 20 -142 0 -150 -1 -164 -22z
//           M17078 1370 c-244 -44 -409 -195 -468 -426 -25 -97 -28 -350 -5 -459 45 -214 206 -380 416 -430 93 -22 267 -20 362 4 181 47 303 151 357 304 33 94 36 141 11 166 -19 19 -32 21 -135 21 -125 0 -141 -6 -152 -58 -30 -143 -107 -202 -263 -202 -110 0 -147 14 -207 74 -72 72 -94 137 -101 291 -11 252 42 387 176 449 69 31 208 30 275 -3 54 -27 101 -90 112 -150 8 -41 22 -73 38 -83 6 -4 62 -8 124 -8 99 0 114 2 132 20 24 24 25 47 5 123 -60 230 -245 363 -520 372 -60 2 -131 0 -157 -5z
//        "
//             />
//           </g>
//         </svg>
//       </div>
//     </Link>
//   </div>
// )

// export const Logo: React.FC<LogoProps> = () => (
//   <div className="header__logo">
//     <Link to="/" className="logo">
//       <div className="logo__slogan">
//         Auto parts for Cars, trucks and motorcycles
//       </div>
//       <div className="logo__image">
//         <svg xmlns="http://www.w3.org/2000/svg" width={168} height={26}>
//           <path
//             className="logo__part-primary"
//             d="M50,26h-5c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h5c6.6,0,12,5.4,12,12v2C62,20.6,56.6,26,50,26z M57,12
// 	c0-3.9-3.1-7-7-7h-0.8C48.5,5,48,5.5,48,6.2v13.6c0,0.7,0.5,1.2,1.2,1.2H50c3.9,0,7-3.1,7-7V12z M38.5,26h-13h-2
// 	c-0.8,0-1.5-0.7-1.5-1.5v-2v-9v-2v-8v-2C22,0.7,22.7,0,23.5,0h2h13C39.3,0,40,0.7,40,1.5v2C40,4.3,39.3,5,38.5,5H27v5h9.5
// 	c0.8,0,1.5,0.7,1.5,1.5v2c0,0.8-0.7,1.5-1.5,1.5H27v6h11.5c0.8,0,1.5,0.7,1.5,1.5v2C40,25.3,39.3,26,38.5,26z M18.8,23.8
// 	c0.6,1-0.1,2.3-1.3,2.3h-2.3c-0.5,0-1-0.3-1.3-0.8L9.7,18H5v6.5C5,25.3,4.3,26,3.5,26h-2C0.7,26,0,25.3,0,24.5v-23
// 	C0,0.7,0.7,0,1.5,0H10c5,0,9,4,9,9c0,3.2-1.7,6.1-4.3,7.7L18.8,23.8z M10,5H6C5.5,5,5,5.4,5,6v6c0,0.6,0.4,1,1,1h4c2.2,0,4-1.8,4-4
// 	S12.2,5,10,5z"
//           />
//           <path
//             className="logo__part-secondary"
//             d="M166.5,8h-2.3c-0.6,0-1.1-0.4-1.4-1c-0.5-1.2-2-2-3.8-2c-2.2,0-4,1.3-4,3c0,0.9,0.6,1.8,1.5,2.3
// 	c0.2,0.1,0.5,0.3,0.7,0.4c0.9,0.3,1.2,1.3,0.7,2.1l-1,1.7c-0.4,0.7-1.2,0.9-1.9,0.6c-1.2-0.5-2.3-1.3-3.1-2.2c-1.2-1.4-2-3.1-2-5
// 	c0-4.4,4-8,9-8c4.3,0,8,2.6,8.9,6.2C168.2,7.1,167.4,8,166.5,8z M151.5,18h2.3c0.6,0,1.1,0.4,1.4,1c0.5,1.2,2,2,3.8,2
// 	c2.2,0,4-1.3,4-3c0-0.9-0.6-1.8-1.5-2.3c-0.2-0.1-0.5-0.3-0.7-0.4c-0.9-0.3-1.2-1.3-0.7-2.1l1-1.7c0.4-0.6,1.2-0.9,1.9-0.6
// 	c1.2,0.5,2.3,1.3,3.1,2.2c1.2,1.4,2,3.1,2,5c0,4.4-4,8-9,8c-4.3,0-8-2.6-8.9-6.2C149.8,18.9,150.6,18,151.5,18z M146.5,5H140v19.5
// 	c0,0.8-0.7,1.5-1.5,1.5h-2c-0.8,0-1.5-0.7-1.5-1.5V5h-6.5c-0.8,0-1.5-0.7-1.5-1.5v-2c0-0.8,0.7-1.5,1.5-1.5h18
// 	c0.8,0,1.5,0.7,1.5,1.5v2C148,4.3,147.3,5,146.5,5z M125.8,23.8c0.6,1-0.2,2.3-1.3,2.3h-2.3c-0.5,0-1-0.3-1.3-0.8l-4.2-7.3H112v6.5
// 	c0,0.8-0.7,1.5-1.5,1.5h-2c-0.8,0-1.5-0.7-1.5-1.5v-23c0-0.8,0.7-1.5,1.5-1.5h8.5c5,0,9,4,9,9c0,3.2-1.7,6.1-4.3,7.7L125.8,23.8z
// 	 M117,5h-4c-0.5,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h4c2.2,0,4-1.8,4-4S119.2,5,117,5z M103.8,26h-2.3c-0.7,0-1.4-0.4-1.6-1.1l-2.4-6.7
// 	c0-0.1-0.1-0.1-0.2-0.1h-7.5c-0.1,0-0.2,0.1-0.2,0.1l-2.4,6.7c-0.2,0.7-0.9,1.1-1.6,1.1h-2.3c-0.8,0-1.4-0.8-1.1-1.6l8.3-23.3
// 	C90.7,0.4,91.3,0,92,0H95c0.7,0,1.4,0.4,1.6,1.1l8.3,23.3C105.2,25.2,104.6,26,103.8,26z M95.5,12.7l-1.8-4.9
// 	c-0.1-0.2-0.3-0.2-0.4,0l-1.8,4.9c0,0.1,0.1,0.3,0.2,0.3h3.5C95.4,13,95.5,12.9,95.5,12.7z M83.9,10.2c0,0.2-0.1,0.4-0.1,0.6
// 	c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.5-0.3,1.1-0.6,1.6c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.4c-0.2,0.4-0.5,0.7-0.8,1.1
// 	c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.5-1.1,0.9-1.7,1.3c-1.4,0.8-3,1.3-4.7,1.3h-5v6.5c0,0.8-0.7,1.5-1.5,1.5
// 	h-2c-0.8,0-1.5-0.7-1.5-1.5v-23C65,0.7,65.7,0,66.5,0H75c1.7,0,3.3,0.5,4.7,1.3c0.6,0.4,1.2,0.8,1.7,1.3c0.1,0.1,0.2,0.2,0.3,0.3
// 	c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.5,0.7,0.8,1.1c0.1,0.1,0.1,0.2,0.2,0.3C83,4.8,83.1,5,83.1,5.1c0.2,0.5,0.4,1,0.6,1.6
// 	c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6C83.9,8,84,8.2,84,8.4c0,0.2,0,0.4,0,0.6s0,0.4,0,0.6C84,9.8,83.9,10,83.9,10.2z M75,5
// 	h-4c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h4c2.2,0,4-1.8,4-4S77.2,5,75,5z"
//           />
//         </svg>
//       </div>
//     </Link>
//   </div>
// )
