import { useCallback, useMemo } from 'react'

import { getSigninSchema } from '@monorepo/validations/SigninSchema'
import { UserCredentialsType } from '@monorepo/deprecated/interfaces'
import { useUser } from './useUser'

export function useSigninForm() {
  const initialValues: UserCredentialsType = useMemo(
    () => ({
      username: '',
      password: '',
    }),
    []
  )

  const { loginHandler } = useUser()

  const onSubmit = useCallback(
    async (credentails: UserCredentialsType) => {
      try {
        await loginHandler(credentails)
      } catch (error) {
        throw new Error('Неверный логин или пароль')
      }
    },
    [loginHandler]
  )

  const validationSchema = useMemo(() => getSigninSchema(), [])

  return { validationSchema, initialValues, onSubmit }
}
