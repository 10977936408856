import React from 'react'

import { GoodType } from '@monorepo/deprecated/interfaces'
import { Link } from 'App/Components/Link'
import { Image } from '@monorepo/components/common/Image'

import placeholderImage from 'App/Resources/images/placeholder-300x300.png'

type PropsType = {
  item: GoodType
}

export const SuggestionItem: React.FC<PropsType> = ({ item }) => (
  <Link
    className="suggestions__item suggestions__product d-flex text-decoration-none"
    to={item.searchLink}
  >
    <div className="suggestions__product-image mr-2 flex-shrink-1">
      <Image
        height={40}
        width={40}
        className="img-thumbnail"
        lazy={false}
        src={item.thumbs?.length ? item.thumbs[0] : placeholderImage}
      />
    </div>
    <div className="suggestions__product-info d-inline-block w-100">
      <div className="suggestions__product-name">{item.name}</div>
      <div className="suggestions__product-rating">
        {/* <div className="suggestions__product-rating-stars">
          <div className="rating">
            <div className="rating__body">
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
              <div className="rating__star rating__star--active-" />
            </div>
          </div>
        </div> */}
        <div className="suggestions__product-rating-label text-truncate">
          {item.description}
        </div>
      </div>
    </div>
    {/* <div className="suggestions__product-price">$224.00</div> */}
  </Link>
)
