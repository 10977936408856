import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { SocialLinkType } from '@monorepo/deprecated/interfaces'

const icons: {
  [name: string]: IconProp
} = {
  youtube: ['fab', 'youtube'],
  facebook: ['fab', 'facebook-f'],
  twitter: ['fab', 'twitter'],
  instagram: ['fab', 'instagram'],
  vk: ['fab', 'vk'],
}

export function getNetworkIcon(link: SocialLinkType) {
  return icons?.[link.name.toLocaleLowerCase()]
}
