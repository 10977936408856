import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'

import { useCatalogsList } from './useCatalogsList'

export function useCatalog() {
  const { name } = useParams<{ name: string }>()
  const { catalogs } = useCatalogsList()
  const dispatch = useDispatch()

  const currentCatalog = catalogs.find((c) => c.id === name)

  useEffect(() => {
    if (!currentCatalog) {
      const firstCatalog = catalogs[0]
      if (firstCatalog) {
        dispatch(push(firstCatalog.mountPoint))
      }
    }
  })

  return { currentCatalog }
}
