import { useCallback } from 'react'
import defaults from 'lodash/defaults'
// import { useDispatch, useSelector } from 'react-redux'
// import { getLocation, push } from 'connected-react-router'
import { useLocation, useHistory } from 'react-router'

import { AclType } from '@monorepo/deprecated/interfaces'
import { useUser } from './useUser'

const defaultAcl: AclType = { guest: true, authenticated: true }

export function useAcl(acl: Partial<AclType> = {}) {
  // const dispatch = useDispatch()
  // const location = useSelector<any>(getLocation)
  const location = useLocation()
  const history = useHistory()
  const { user, loading } = useUser()

  const currentAcl = defaults(acl, defaultAcl)

  let isAllow = false
  let isReady = false

  if (user.isGuest) {
    isAllow = currentAcl.guest
    isReady = true
  } else {
    isAllow = !loading && currentAcl.authenticated
    isReady = !loading
  }

  const getPermissions = useCallback(
    (acl: Partial<AclType>) => {
      const currentAcl = defaults(acl, defaultAcl)

      let isAllow = false
      let isReady = false

      if (user.isGuest) {
        isAllow = currentAcl.guest
        isReady = true
      } else {
        isAllow = !loading && currentAcl.authenticated
        isReady = !loading
      }
      return { isAllow, isReady }
    },
    [loading, user.isGuest]
  )

  const checkPermissions = useCallback(
    async (acl: Partial<AclType>) => {
      const { isReady, isAllow } = getPermissions(acl)

      if (isReady && !isAllow) {
        history.push(user.isGuest ? '/account/login' : '/', {
          referrer: location,
        })
        // dispatch(
        //   push(
        //     {
        //       pathname: user.isGuest ? '/account/login' : '/',
        //       search: '',
        //       hash: '',
        //     },
        //     { referrer: location }
        //   )
        // )
        return false
      }

      return true
    },
    [getPermissions, history, location, user.isGuest]
  )

  return {
    isReady,
    isAllow,
    isDissallow: !isAllow,
    isGuest: user.isGuest,
    checkPermissions,
  }
}
