import { useCallback, useEffect, useRef, useState } from 'react'
import { delay } from 'lodash'
import { useHistory } from 'react-router'
// import { useSelector } from 'react-redux'
// import { useLocation } from 'react-router'

type OptionsType = {
  onShow?(): void
  onHide?(): void
}

export function useComponentVisible(
  initialIsVisible: boolean,
  options: OptionsType = {}
) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const componentRef = useRef<any>(null)

  const history = useHistory()

  // const location = useSelector(useLocation)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target) &&
        isComponentVisible
      ) {
        delay(setIsComponentVisible, 0, false)
      }
    },
    [isComponentVisible]
  )

  const showComponentHandler = useCallback(() => {
    setIsComponentVisible(true)
    options.onShow?.()
  }, [options])

  const hideComponentHandler = useCallback(() => {
    setIsComponentVisible(false)
    options.onHide?.()
  }, [options])

  const toggleComponentHandler = useCallback(
    () =>
      isComponentVisible ? hideComponentHandler() : showComponentHandler(),
    [hideComponentHandler, isComponentVisible, showComponentHandler]
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    const unlisten = history.listen(
      initialIsVisible ? showComponentHandler : hideComponentHandler
    )
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
      unlisten()
    }
  }, [
    handleClickOutside,
    hideComponentHandler,
    history,
    initialIsVisible,
    showComponentHandler,
  ])

  return {
    componentRef,
    isComponentVisible,
    showComponentHandler,
    hideComponentHandler,
    toggleComponentHandler,
  }
}
