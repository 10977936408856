import { useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { FormikConfig } from 'formik'
import { useHistory } from 'react-router'
import qs from 'qs'

import { CartItemType } from '@monorepo/deprecated/interfaces'
import { createOrderAction } from '@monorepo/deprecated/core/features/cart'
import { getOrderSchema } from '@monorepo/validations'
import { useDeliveryMethodsList } from '@monorepo/hooks/Delivery'
import { useUser } from '@monorepo/hooks/User'
import { /*usePayment,*/ usePaymentMethodsList } from '@monorepo/hooks/Payment'
import { IOrder } from '../interfaces'

export type FormValuesType = {
  paymentMethod: string
  deliveryType: string
  deliveryAddress: string
  pickPoint?: string
  customerComment?: string
  items: CartItemType[]
}

type OptionsType = {
  onSuccess?(): void
  onError?(): void
}

type CreateUrlOptionsType = {
  newOrder?: boolean
}
const createUrl = (order: IOrder, options?: CreateUrlOptionsType) =>
  `/cart/order/${order!.id}${qs.stringify(options, { addQueryPrefix: true })}`

export function useOrderForm(items: CartItemType[], options?: OptionsType) {
  const { data: deliveryMethods } = useDeliveryMethodsList()
  const { data: paymentMethods } = usePaymentMethodsList()
  const { user } = useUser()
  // const { paymentHandler } = usePayment()

  const defaultPaymentMethod =
    paymentMethods?.find((method) => method.isDefault) ?? paymentMethods?.[0]
  const defaultDeliveryMethod =
    deliveryMethods?.find((method) => method.isDefault) ?? deliveryMethods?.[0]

  const initialValues: FormValuesType = useMemo(
    () => ({
      paymentMethod: String(defaultPaymentMethod?.id ?? ''),
      deliveryType: String(defaultDeliveryMethod?.id ?? ''),
      deliveryAddress: user.profile.address || '',
      pickPoint: undefined,
      customerComment: '',
      items,
    }),
    [defaultDeliveryMethod, defaultPaymentMethod, items, user.profile.address]
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const createOrder = useCallback(
    bindActionCreators(createOrderAction, dispatch),
    []
  )

  const validationSchema = useMemo(() => getOrderSchema({ deliveryMethods }), [
    deliveryMethods,
  ])

  const onSubmit = useCallback(
    async (values: FormValuesType) => {
      try {
        const { response } = (await createOrder(values)) as any
        const order = response.data as IOrder
        // const currentPaymentMethod = paymentMethods?.find(
        //   (item) => String(item.id) === String(values.paymentMethod)
        // )

        // paymentHandler?.(order, currentPaymentMethod)
        options?.onSuccess?.()
        history.push(createUrl(order, { newOrder: true }))
      } catch (error) {
        options?.onError?.()
        // throw error
      }
    },
    [createOrder, history, options]
  )

  const bind: FormikConfig<FormValuesType> = {
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
  }

  return {
    bind,
    deliveryMethods,
    paymentMethods,
  }
}
