import * as yup from 'yup'
import { isEmpty, isEqual, isFunction } from 'lodash'

import './index.d'
import { messages } from './messages'

yup.setLocale(messages)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'phoneNumber',
  function (locale: string) {
    return this.test({
      name: 'phoneNumber',
      exclusive: true,
      message: messages.string.phoneNumber,
      test: async (value) => {
        if (!value) return true
        try {
          const { PhoneNumberUtil } = await import('google-libphonenumber')
          const phoneUtil = PhoneNumberUtil.getInstance()
          const phone = phoneUtil.parse(value, locale)
          return phoneUtil.isValidNumber(phone)
        } catch (e) {
          return false
        }
      },
    })
  }
)

yup.addMethod<yup.StringSchema>(
  yup.string,
  'vinNumber',
  function (locale: string) {
    return this.test({
      name: 'vinNumber',
      exclusive: true,
      message: messages.string.vinNumber,
      test: (value) => {
        return this.length(17)
          .matches(/^[A-HJ-NPR-Z\d]{13}\d{4}$/i)
          .isValid(value)
      },
    })
  }
)

yup.addMethod<yup.StringSchema>(
  yup.mixed,
  'sameAs',
  function (ref: yup.Ref, message: string) {
    return this.test({
      name: 'sameAs',
      message,
      test: function (value) {
        const other = this.resolve(ref)
        return isEmpty(other) || isEqual(other, value)
      },
    })
  }
)

yup.addMethod<yup.NumberSchema>(
  yup.number,
  'multiplicity',
  function (ref: yup.Ref, message = messages.number.multiplicity) {
    return this.test({
      name: 'multiplicity',
      test: function (value) {
        const multiplicity = this.resolve(ref)

        return value % multiplicity !== 0
          ? this.createError({
              message: isFunction(message)
                ? ({ path }: any) =>
                    message({
                      path,
                      multiplicity,
                    })
                : message,
              path: this.path,
            })
          : true
      },
    })
  }
)
