import configurableComponent from '@monorepo/core/utils/configurableComponent'
import BaseLoader, { LoaderProps } from './Loader'

const defaultConfig: LoaderProps = {
  size: undefined,
  borderSize: undefined,
  image: undefined,
  text: undefined,
}

const [withConfig, configure] = configurableComponent(defaultConfig)

export { configure }

export const Loader = (withConfig(
  BaseLoader
) as unknown) as React.FC<LoaderProps>

export default Loader
