import React from 'react'
import cn from 'classnames'
import styled from 'styled-components'

import { GoodType } from '@monorepo/deprecated/interfaces'
import { SuggestionItem } from './SuggestionItem'

type PropsType = {
  className?: string
  height?: number | string
  open?: boolean
  items?: Array<GoodType>
}

const BaseSuggestionsList: React.FC<PropsType> = ({
  open = false,
  items = [],
  className,
}) => (
  <div
    className={cn(
      className,
      'search__dropdown',
      'search__dropdown--suggestions',
      'suggestions',
      { 'search__dropdown--open': open },
      { 'd-none': !open }
    )}
  >
    {items.length > 0 && (
      <div className="suggestions__group">
        <div className="suggestions__group-title">Наименования</div>
        <div className="suggestions__group-content">
          {items.map((item) => (
            <SuggestionItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    )}
    {/* <div className="suggestions__group">
      <div className="suggestions__group-title">Categories</div>
      <div className="suggestions__group-content">
        <a className="suggestions__item suggestions__category" href="/">
          Headlights &amp; Lighting
        </a>
        <a className="suggestions__item suggestions__category" href="/">
          Fuel System &amp; Filters
        </a>
        <a className="suggestions__item suggestions__category" href="/">
          Body Parts &amp; Mirrors
        </a>
        <a className="suggestions__item suggestions__category" href="/">
          Interior Accessories
        </a>
      </div>
    </div> */}
  </div>
)

export const SuggestionsList = styled(BaseSuggestionsList)`
  max-height: ${(props) => (props.height ? `${props.height}` : 'inherit')};
  overflow-y: scroll;
  // padding: 12px;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  top: 100%;
`
