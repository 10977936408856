import React from 'react'
import cn from 'classnames'

import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { useCompany } from '@monorepo/hooks/App/useCompany'
import { Link } from 'App/Components/Link'
import { MenuPanel } from './MenuPanel'
import { IndicatorsList } from './IndicatorsList'
import { LinksList } from './LinksList'

type MobileMenuPropsType = {
  menu: MenuItemType[]
  show?: boolean
  ref?: React.Ref<HTMLDivElement>
  onHide?(): void
}

export const MobileMenu: React.FC<MobileMenuPropsType> = React.forwardRef<
  HTMLDivElement,
  MobileMenuPropsType
>(({ menu, show = false, onHide }, ref) => {
  const { company, mainPhone } = useCompany()

  return (
    <div className={cn('mobile-menu', { 'mobile-menu--open': show })}>
      <div className="mobile-menu__backdrop" />
      <div className="mobile-menu__body" ref={ref}>
        <button className="mobile-menu__close" type="button" onClick={onHide}>
          <svg width={12} height={12}>
            <path
              d="M10.8,10.8L10.8,10.8c-0.4,0.4-1,0.4-1.4,0L6,7.4l-3.4,3.4c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L4.6,6L1.2,2.6
	c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L6,4.6l3.4-3.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L7.4,6l3.4,3.4
	C11.2,9.8,11.2,10.4,10.8,10.8z"
            />
          </svg>
        </button>
        {/* <div className="mobile-menu__panel">
          <div className="mobile-menu__panel-header">
            <div className="mobile-menu__panel-title">Меню</div>
          </div>
          <div className="mobile-menu__panel-body"> */}

        <MenuPanel title="Меню">
          {/* <div className="mobile-menu__settings-list">
            <div className="mobile-menu__setting" data-mobile-menu-item>
              <button className="mobile-menu__setting-button" title="Language">
                <span className="mobile-menu__setting-icon">
                  <img src="images/languages/language-5.png" alt="" />{' '}
                </span>
                <span className="mobile-menu__setting-title">Italian</span>{' '}
                <span className="mobile-menu__setting-arrow">
                  <svg width="6px" height="9px">
                    <path d="M0.3,7.4l3-2.9l-3-2.9c-0.4-0.3-0.4-0.9,0-1.3l0,0c0.4-0.3,0.9-0.4,1.3,0L6,4.5L1.6,8.7c-0.4,0.4-0.9,0.4-1.3,0l0,0C-0.1,8.4-0.1,7.8,0.3,7.4z" />
                  </svg>
                </span>
              </button>
              <div
                className="mobile-menu__setting-panel"
                data-mobile-menu-panel
              >
                <div className="mobile-menu__panel mobile-menu__panel--hidden">
                  <div className="mobile-menu__panel-header">
                    <button className="mobile-menu__panel-back" type="button">
                      <svg width={7} height={11}>
                        <path d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                      </svg>
                    </button>
                    <div className="mobile-menu__panel-title">Language</div>
                  </div>
                  <div className="mobile-menu__panel-body">
                    <ul className="mobile-menu__links">
                      <li data-mobile-menu-item>
                        <button type="button">
                          <div className="mobile-menu__links-image">
                            <img src="images/languages/language-1.png" alt="" />
                          </div>
                          English
                        </button>
                      </li>
                      <li data-mobile-menu-item>
                        <button type="button">
                          <div className="mobile-menu__links-image">
                            <img src="images/languages/language-2.png" alt="" />
                          </div>
                          French
                        </button>
                      </li>
                      <li data-mobile-menu-item>
                        <button type="button">
                          <div className="mobile-menu__links-image">
                            <img src="images/languages/language-3.png" alt="" />
                          </div>
                          German
                        </button>
                      </li>
                      <li data-mobile-menu-item>
                        <button type="button">
                          <div className="mobile-menu__links-image">
                            <img src="images/languages/language-4.png" alt="" />
                          </div>
                          Russian
                        </button>
                      </li>
                      <li data-mobile-menu-item>
                        <button type="button">
                          <div className="mobile-menu__links-image">
                            <img src="images/languages/language-5.png" alt="" />
                          </div>
                          Italian
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-menu__setting" data-mobile-menu-item>
              <button className="mobile-menu__setting-button" title="Currency">
                <span className="mobile-menu__setting-icon mobile-menu__setting-icon--currency">
                  ${' '}
                </span>
                <span className="mobile-menu__setting-title">US Dollar</span>{' '}
                <span className="mobile-menu__setting-arrow">
                  <svg width="6px" height="9px">
                    <path d="M0.3,7.4l3-2.9l-3-2.9c-0.4-0.3-0.4-0.9,0-1.3l0,0c0.4-0.3,0.9-0.4,1.3,0L6,4.5L1.6,8.7c-0.4,0.4-0.9,0.4-1.3,0l0,0C-0.1,8.4-0.1,7.8,0.3,7.4z" />
                  </svg>
                </span>
              </button>
              <div
                className="mobile-menu__setting-panel"
                data-mobile-menu-panel
              >
                <div className="mobile-menu__panel mobile-menu__panel--hidden">
                  <div className="mobile-menu__panel-header">
                    <button className="mobile-menu__panel-back" type="button">
                      <svg width={7} height={11}>
                        <path d="M6.7,0.3L6.7,0.3c-0.4-0.4-0.9-0.4-1.3,0L0,5.5l5.4,5.2c0.4,0.4,0.9,0.3,1.3,0l0,0c0.4-0.4,0.4-1,0-1.3l-4-3.9l4-3.9C7.1,1.2,7.1,0.6,6.7,0.3z" />
                      </svg>
                    </button>
                    <div className="mobile-menu__panel-title">Currency</div>
                  </div>
                  <div className="mobile-menu__panel-body">
                    <ul className="mobile-menu__links">
                      <li data-mobile-menu-item>
                        <button type="button">€ Euro</button>
                      </li>
                      <li data-mobile-menu-item>
                        <button type="button">£ Pound Sterling</button>
                      </li>
                      <li data-mobile-menu-item>
                        <button type="button">$ US Dollar</button>
                      </li>
                      <li data-mobile-menu-item>
                        <button type="button">₽ Russian Ruble</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="mobile-menu__divider" />
          <IndicatorsList />
          <div className="mobile-menu__divider" />
          <LinksList items={menu} />
          <div className="mobile-menu__spring" />
          <div className="mobile-menu__divider" />
          <Link to="/contacts" className="mobile-menu__contacts">
            <div className="mobile-menu__contacts-subtitle">
              {company.address?.fullAddress}
            </div>
            {mainPhone && (
              <div className="mobile-menu__contacts-title">
                {mainPhone.number}
              </div>
            )}
          </Link>
        </MenuPanel>
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  )
})
