import React from 'react'

import { MenuType } from '@monorepo/deprecated/interfaces'
import { useCompany } from '@monorepo/hooks/App/useCompany'
import { useContract } from '@monorepo/hooks/App/useContract'
import { useUser } from '@monorepo/hooks/User/useUser'
import { Currency } from '@monorepo/components/common/Currency'
import { Link } from 'App/Components/Link'
import { MenuItem } from './MenuItem'
import { ContractsMenu } from './ContractsMenu'

type TopToolbarProps = {
  //
}

const leftMenu: MenuType = [
  {
    name: 'О нас',
    url: '/about-us',
  },
  {
    name: 'Контакты',
    url: '/contacts',
  },
  {
    name: 'Блог',
    url: '/blog',
  },
]

export const TopToolbar: React.FC<TopToolbarProps> = () => {
  const { mainPhone } = useCompany()
  const {
    currentContract,
    orderContracts,
    selectContractHandler,
  } = useContract()
  const { user } = useUser()

  return (
    <>
      <div className="header__topbar-start-bg" />
      <div className="header__topbar-start">
        <div className="topbar topbar--spaceship-start">
          <div className="topbar__item-text d-none d-xxl-flex">
            Наш телефон: {mainPhone?.number}
          </div>
          {leftMenu.map((item, idx) => (
            <MenuItem key={idx} item={item} />
          ))}
        </div>
      </div>
      <div className="header__topbar-end-bg" />
      <div className="header__topbar-end">
        <div className="topbar topbar--spaceship-end">
          {!user.isGuest && currentContract && (
            <div className="topbar__item-button">
              <Link to="/" className="topbar__button">
                <span className="topbar__button-label">Баланс:</span>
                <span className="topbar__button-title">
                  <Currency value={currentContract?.balance} />
                </span>
              </Link>
            </div>
          )}
          {!user.isGuest && (
            <ContractsMenu
              currentItem={currentContract}
              items={orderContracts}
              onSelect={selectContractHandler}
            />
          )}
          {/* <div className="topbar__item-button topbar__menu">
            <button
              className="topbar__button topbar__button--has-arrow topbar__menu-button"
              type="button"
            >
              <span className="topbar__button-label">Currency:</span>
              <span className="topbar__button-title">USD</span>
              <span className="topbar__button-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7px"
                  height="5px"
                >
                  <path d="M0.280,0.282 C0.645,-0.084 1.238,-0.077 1.596,0.297 L3.504,2.310 L5.413,0.297 C5.770,-0.077 6.363,-0.084 6.728,0.282 C7.080,0.634 7.088,1.203 6.746,1.565 L3.504,5.007 L0.262,1.565 C-0.080,1.203 -0.072,0.634 0.280,0.282 Z" />
                </svg>
              </span>
            </button>
            <div className="topbar__menu-body">
              <a className="topbar__menu-item" href="#">
                € Euro
              </a>
              <a className="topbar__menu-item" href="#">
                £ Pound Sterling
              </a>
              <a className="topbar__menu-item" href="#">
                $ US Dollar
              </a>
              <a className="topbar__menu-item" href="#">
                ₽ Russian Ruble
              </a>
            </div>
          </div> */}
          {/* <div className="topbar__menu">
            <button
              className="topbar__button topbar__button--has-arrow topbar__menu-button"
              type="button"
            >
              <span className="topbar__button-label">Language:</span>
              <span className="topbar__button-title">EN</span>
              <span className="topbar__button-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7px"
                  height="5px"
                >
                  <path d="M0.280,0.282 C0.645,-0.084 1.238,-0.077 1.596,0.297 L3.504,2.310 L5.413,0.297 C5.770,-0.077 6.363,-0.084 6.728,0.282 C7.080,0.634 7.088,1.203 6.746,1.565 L3.504,5.007 L0.262,1.565 C-0.080,1.203 -0.072,0.634 0.280,0.282 Z" />
                </svg>
              </span>
            </button>
            <div className="topbar__menu-body">
              <a className="topbar__menu-item" href="#">
                <img src="images/languages/language-1.png" alt="" />
                <span>English</span>
              </a>
              <a className="topbar__menu-item" href="#">
                <img src="images/languages/language-2.png" alt="" />
                <span>French</span>
              </a>
              <a className="topbar__menu-item" href="#">
                <img src="images/languages/language-3.png" alt="" />
                <span>German</span>
              </a>
              <a className="topbar__menu-item" href="#">
                <img src="images/languages/language-4.png" alt="" />
                <span>Russian</span>
              </a>
              <a className="topbar__menu-item" href="#">
                <img src="images/languages/language-5.png" alt="" />
                <span>Italian</span>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}
