import React, { useCallback } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleNotch,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { invoke } from 'lodash'

import { Util, useGoodSuggestion } from '@monorepo/hooks'
import { SuggestionsList } from './SuggestionsList'
import { VehicleButton /*VehiclePicker*/ } from './Vehicle'

const showVehicleSelector = true

type SearchFieldPropsType = {
  mobile?: boolean
  onHide?(): void
}

export const SearchField: React.FC<SearchFieldPropsType> = ({
  mobile = false,
  onHide,
}) => {
  const { query, suggestions, handleChange, loading } = useGoodSuggestion()

  const {
    isComponentVisible,
    hideComponentHandler,
    showComponentHandler,
    componentRef,
  } = Util.useComponentVisible(false)

  const handleClick = useCallback(
    (event) => {
      invoke(event, 'target.select')
      showComponentHandler()
    },
    [showComponentHandler]
  )

  const handleHide = useCallback(() => {
    onHide?.()
    hideComponentHandler()
  }, [hideComponentHandler, onHide])

  return (
    <form
      className={cn(
        { search__body: !mobile },
        { 'mobile-search__body': mobile }
      )}
      ref={componentRef}
      onSubmit={(event) => {
        event.preventDefault()
      }}
    >
      {!mobile && <div className="search__shadow" />}
      <input
        className={cn(
          { 'text-uppercase': query.length > 0 },
          { search__input: !mobile },
          { 'mobile-search__input': mobile }
        )}
        type="text"
        placeholder="Номер запчасти..."
        onChange={(event) => handleChange(event.target.value)}
        value={query}
        onClick={handleClick}
        // onBlur={hideComponentHandler}
      />
      <button
        className={cn(
          { search__button: !mobile, 'search__button--end': !mobile },
          {
            'mobile-search__button': mobile,
            'mobile-search__button--search': mobile,
          }
        )}
        type="submit"
      >
        <span className="search__button-icon">
          <FontAwesomeIcon
            icon={loading ? faCircleNotch : faSearch}
            spin={loading}
          />
        </span>
      </button>
      {mobile && (
        <button
          type="button"
          className="mobile-search__button mobile-search__button--close"
          onClick={handleHide}
        >
          <FontAwesomeIcon icon={faTimes} />
          {/* <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}>
            <path
              d="M16.7,16.7L16.7,16.7c-0.4,0.4-1,0.4-1.4,0L10,11.4l-5.3,5.3c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L8.6,10L3.3,4.7
c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L10,8.6l5.3-5.3c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L11.4,10l5.3,5.3
C17.1,15.7,17.1,16.3,16.7,16.7z"
            />
          </svg> */}
        </button>
      )}
      <SuggestionsList
        height="50vh"
        open={isComponentVisible}
        items={suggestions}
      />
      {showVehicleSelector && <VehicleButton />}

      <div className="search__box" />
      <div className="search__decor">
        <div className="search__decor-start" />
        <div className="search__decor-end" />
      </div>

      {/* {showVehicleSelector && <VehiclePicker />} */}
      {/* {mobile && <div className="mobile-search__field" />} */}
    </form>
  )
}
