import { useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import {
  contractsSelector,
  currentContractSelector,
  orderContractsSelector,
  setCurrentContractAction,
} from '@monorepo/deprecated/core/features/Contract'

export function useContract() {
  const { data: contracts } = useSelector(contractsSelector)
  const currentContract = useSelector(currentContractSelector)
  const orderContracts = useSelector(orderContractsSelector)

  const dispatch = useDispatch()

  const selectContractHandler = useCallback(
    bindActionCreators(setCurrentContractAction, dispatch),
    []
  )

  return { contracts, currentContract, orderContracts, selectContractHandler }
}
