import React from 'react'

import { SocialLinkType } from '@monorepo/deprecated/interfaces'
import { LinkItem } from './LinkItem'

type SocialNetworksListPropsType = {
  items: Array<SocialLinkType>
}

export const SocialNetworksList: React.FC<SocialNetworksListPropsType> = ({
  items,
}) => (
  <ul className="social-links__list">
    {items.map((link) => (
      <LinkItem key={link.name} link={link} />
    ))}
  </ul>
)
