import React from 'react'

import { MainHeader } from './MainHeader'
import { MobileHeader } from './MobileHeader'

import { useMenu } from '@monorepo/hooks/App/useMenu'
import logoImage from 'App/Resources/images/Logo_territory_mmc.png'

type HeaderPropsType = {
  //
}

export const Header: React.FC<HeaderPropsType> = () => {
  const { menu } = useMenu('main')

  return (
    <>
      <MainHeader logoImage={logoImage} menu={menu} />
      <MobileHeader logoImage={logoImage} menu={menu} />
    </>
  )
}
