// import { bindActionCreators } from 'redux'
import { /*useDispatch,*/ useSelector } from 'react-redux'
import { find, first } from 'lodash'

import { companySelector } from '@monorepo/deprecated/core/features/Company'

export function useCompany() {
  const { data: company } = useSelector(companySelector)
  const salesPoint = company.offices

  const mainPhone = find(company.phones, 'isMain') || first(company.phones)

  return { company, salesPoint, mainPhone }
}
