import { useCallback, useRef } from 'react'
// import '@monorepo/core/utils/jquery-loader'
import OwlCarousel from 'react-owl-carousel'

export function useCarouselControl(
  speed: number = 500
): {
  carouselRef: React.RefObject<any>
  prevHandler: () => void
  nextHandler: () => void
} {
  const carouselRef = useRef<OwlCarousel>(null)

  const prevHandler = useCallback(() => {
    carouselRef.current && carouselRef.current.prev(speed)
  }, [carouselRef, speed])

  const nextHandler = useCallback(() => {
    carouselRef.current && carouselRef.current.next(speed)
  }, [carouselRef, speed])

  return { carouselRef, prevHandler, nextHandler }
}
