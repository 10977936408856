import { useState } from 'react'

import { useQuery /*AnyQueryKey*/ } from 'react-query'

import { ReconciliationType as BaseReconciliationType } from '@monorepo/deprecated/interfaces'
import { useClient } from '@monorepo/core/api_module'

export type ReconciliationType = BaseReconciliationType & {
  // items?: any[]
  // operations: any[]
  // isExpanded: boolean
  // isLoaded: boolean
  // isFetching: boolean
}

export type ItemType = {
  amount: number
  brand: string
  description: string
  number: string
  price: number
  quality: string
  reference: string
  sum: number
  unit: string
}

export type DetailsType = {
  items?: ItemType[]
  name: string
  sum: number
}

export const useDetails = (item: ReconciliationType) => {
  const client = useClient()

  const [isLoaded, setLoaded] = useState(false)

  const fetcher = async (id: string) => {
    try {
      const { data } = await client.get(`/contracts/transactions/${item.id}`)
      setLoaded(true)
      return data
    } catch (error) {}
  }

  const { data, status, error, isFetching } = useQuery<DetailsType, string>(
    ['fetchDetails', item.id],
    () => fetcher(item.id),
    {
      enabled: item.isExpanded || isLoaded,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
    }
  )

  return { data, status, error, isFetching }
}
