import React from 'react'

import { SearchField } from 'App/Components/SearchField'

type SearchProps = {
  //
}

export const Search: React.FC<SearchProps> = () => (
  <div className="header__search">
    <div className="search">
      <SearchField />
    </div>
  </div>
)
