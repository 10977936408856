import React from 'react'
import { FormatNumberOptions, FormattedNumber } from 'react-intl'

const defaultProps: Partial<FormatNumberOptions> = {
  currency: 'RUB',
  style: 'currency',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

// function normalizeFormat(name) {
//   const re = /[^а-яa-z]/gi
//   return isEmpty(name) ? undefined : replace(name, re, '').toUpperCase()
// }

export const Currency = ({
  ...props
}: Partial<FormatNumberOptions> & { value: number }) => (
  <FormattedNumber {...defaultProps} {...props} />
)
