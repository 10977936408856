import React from 'react'
import { Redirect, useLocation } from 'react-router'

import { AclType } from '@monorepo/deprecated/interfaces'
import { useAcl } from '@monorepo/hooks/User/useAcl'
import { Breadcrumbs } from './Breadcrumbs'

type PropsType = {
  title?: React.ReactNode
  header?: React.ReactNode
  topSpace?: boolean
  bottomSpace?: boolean
  acl?: Partial<AclType>
}

export const Page: React.FC<PropsType> = ({
  children,
  header,
  title,
  topSpace = false,
  bottomSpace = false,
  acl,
}) => {
  const { isDissallow, isReady, isGuest } = useAcl(acl)
  const location = useLocation()

  if (isReady && isDissallow) {
    return (
      <Redirect
        to={{
          pathname: isGuest ? '/account/login' : '/',
          state: { referrer: location },
        }}
      />
    )
  }

  return (
    <div>
      {header}
      {title && (
        <div className="block-header block-header--has-breadcrumb block-header--has-title">
          <div className="container">
            <div className="block-header__body">
              <Breadcrumbs title={title} />
              <h1 className="block-header__title">{title}</h1>
            </div>
          </div>
        </div>
      )}
      {topSpace && (
        <div className="block-space block-space--layout--after-header" />
      )}
      {children}
      {bottomSpace && (
        <div className="block-space block-space--layout--before-footer" />
      )}
    </div>
  )
}
