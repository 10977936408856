import React from 'react'

import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { useCompany } from '@monorepo/hooks/App/useCompany'
import { Menu } from './Menu'
import { MenuButton } from './MenuButton'

type NavbarPropsType = {
  menu: MenuItemType[]
}

export const NavbarOld: React.FC<NavbarPropsType> = ({ menu }) => {
  const { mainPhone } = useCompany()

  return (
    <div className="header__navbar">
      <MenuButton />
      <Menu items={menu} />
      {mainPhone && (
        <div className="header__navbar-phone phone">
          <a href={`tel:${mainPhone.number}`} className="phone__body">
            <div className="phone__title">Позвонить нам:</div>
            <div className="phone__number">{mainPhone.number}</div>
          </a>
        </div>
      )}
    </div>
  )
}

export const Navbar: React.FC<NavbarPropsType> = ({ menu }) => (
  <div className="header__navbar">
    <MenuButton />
    <Menu items={menu} />
  </div>
)
