// import { bindActionCreators } from 'redux'
import { /*useDispatch,*/ useSelector } from 'react-redux'
import { intersection, filter, flow, map } from 'lodash/fp'

import { MenuType, MenuItemType } from '@monorepo/deprecated/interfaces'
import { User } from '@monorepo/hooks'
import { getMenuSelector } from '@monorepo/deprecated/core/features/Menu'

export function useMenu(name: string) {
  const getMenu = useSelector(getMenuSelector)
  const { data: menu } = getMenu(name)

  const { user } = User.useUser()

  const createMenu: (menu: MenuType) => MenuType = flow(
    filter(({ roles }) => !roles || intersection(roles, user.roles).length > 0),
    map<MenuItemType, MenuItemType>((item) =>
      item.menu && item.menu.length > 0
        ? { ...item, menu: createMenu(item.menu) }
        : item
    )
  )

  return { menu: createMenu(menu) }
}
