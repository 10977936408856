import React, { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'

import { initializeAction } from '@monorepo/deprecated/core/features/auth/actions'
import { useAuthCompability } from '@monorepo/deprecated/core/features/auth/hooks'
import { useAutoScrollToTop } from '@monorepo/hooks/Util'

import { App } from 'App'

function useInit() {
  const dispatch = useDispatch()

  const initAuth = useCallback(
    bindActionCreators(initializeAction, dispatch),
    []
  )

  useEffect(() => {
    initAuth()
  }, [initAuth])
}

export default (props) => {
  useInit()
  useAuthCompability()
  useAutoScrollToTop()
  return <App {...props} />
}
