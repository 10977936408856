import React from 'react'
import cn from 'classnames'
import truncate from 'lodash/truncate'
import Button from 'react-bootstrap/Button'

import { Currency } from '@monorepo/components/common/Currency'
import { ContractType } from '@monorepo/deprecated/interfaces'
import { useComponentVisible } from '@monorepo/hooks/Util/useComponentVisible'

type ContractsMenuPropsType = {
  currentItem?: ContractType
  items: Array<ContractType>
  onSelect(item: ContractType): void
  className?: string
}

const MenuItem: React.FC<{ item: ContractType; onSelect(): void }> = ({
  item,
  onSelect,
}) => (
  <Button
    variant="link"
    className="topbar__menu-item w-100 text-decoration-none text-reset text-left font-weight-light"
    onClick={onSelect}
  >
    {item.name}
    <br />
    <small
      className={cn({
        'text-danger': item.balance < 0,
        'text-success': item.balance > 0,
      })}
    >
      {item.balance < 0
        ? 'Задолженность'
        : item.balance > 0
        ? 'Предоплата'
        : 'Баланс'}{' '}
      <Currency value={Math.abs(item.balance)} />
    </small>
  </Button>
)

export const ContractsMenu: React.FC<ContractsMenuPropsType> = ({
  currentItem,
  items,
  className,
  onSelect,
}) => {
  const {
    componentRef,
    isComponentVisible,
    toggleComponentHandler,
  } = useComponentVisible(false)
  return (
    <div
      className={cn(
        'topbar__menu',
        {
          'topbar__menu--open': isComponentVisible,
        },
        {
          [className as string]: !!className,
        }
      )}
    >
      <button
        className="topbar__button topbar__button--has-arrow topbar__menu-button"
        type="button"
        onClick={toggleComponentHandler}
      >
        <span className="topbar__button-label">Договор:</span>
        <span className="topbar__button-title">
          {currentItem
            ? truncate(currentItem.name, { length: 30 })
            : 'не указан'}
        </span>
        <span className="topbar__button-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="5px">
            <path d="M0.280,0.282 C0.645,-0.084 1.238,-0.077 1.596,0.297 L3.504,2.310 L5.413,0.297 C5.770,-0.077 6.363,-0.084 6.728,0.282 C7.080,0.634 7.088,1.203 6.746,1.565 L3.504,5.007 L0.262,1.565 C-0.080,1.203 -0.072,0.634 0.280,0.282 Z" />
          </svg>
        </span>
      </button>
      <div
        className="topbar__menu-body p-0"
        style={{ width: 'auto', maxWidth: 350 }}
        ref={componentRef}
      >
        {items.map((item) => (
          <MenuItem
            key={item.id}
            item={item}
            onSelect={onSelect.bind(null, item)}
          />
        ))}
      </div>
    </div>
  )
}
