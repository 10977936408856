import { useMemo } from 'react'

import { CartItemType, OfferType } from '@monorepo/deprecated/interfaces'
import { IOfferGood } from '@monorepo/interfaces'

export function useItemImages(item: OfferType | CartItemType | IOfferGood) {
  return useMemo(() => {
    const images =
      Array.isArray(item.images) && item.images.length > 0
        ? item.images
        : Array.isArray(item.good.images)
        ? item.good.images
        : undefined

    const thumbs =
      Array.isArray(item.thumbs) && item.thumbs.length > 0
        ? item.thumbs
        : Array.isArray(item.good.thumbs)
        ? item.good.thumbs
        : undefined
    return { images, thumbs }
  }, [item.good.images, item.good.thumbs, item.images, item.thumbs])
}
