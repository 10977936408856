import { useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import {
  createCarAction,
  fetchCarsAction,
  updateCarAction,
  removeCarAction,
  clearCarsAction,
  garageSelector,
} from '@monorepo/deprecated/core/features/Garage'

export function useGarage() {
  const dispatch = useDispatch()
  const {
    data: { cars },
    loading,
  } = useSelector(garageSelector)

  const fetchCars = useCallback(
    bindActionCreators(fetchCarsAction, dispatch),
    []
  )
  const clearCars = useCallback(
    bindActionCreators(clearCarsAction, dispatch),
    []
  )

  const handleAdd = useCallback(
    bindActionCreators(createCarAction, dispatch),
    []
  )

  const handleUpdate = useCallback(
    bindActionCreators(updateCarAction, dispatch),
    []
  )

  const handleRemove = useCallback(
    bindActionCreators(removeCarAction, dispatch),
    []
  )

  useEffect(() => {
    fetchCars()
    return function () {
      clearCars()
    }
  }, [clearCars, fetchCars])

  return { cars, loading, handleAdd, handleUpdate, handleRemove, fetchCars }
}
