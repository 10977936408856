import { getQuery } from 'redux-saga-requests'

import { FETCH_BRANDS, FETCH_MODELS } from './constants'
import { CarBrandType, CarModelType } from '@monorepo/deprecated/interfaces'

export const brandsSelector = getQuery<CarBrandType[]>({
  type: FETCH_BRANDS,
  multiple: true,
  defaultData: [],
})

export const modelsSelector = getQuery<CarModelType[]>({
  type: FETCH_MODELS,
  multiple: true,
  defaultData: [],
})
