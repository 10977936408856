import { useClient } from '@monorepo/core/api_module'

export interface PriceLevel {
  id: string
  name: string
}

interface Operation {
  title?: any
  name: string
  description: string
  price: number
  nextPrice: number
}

interface IPriceInfo {
  priceLevel: PriceLevel
  operations: Operation[]
  purchasePrice: number
  wholesalePrice: number
  retailPrice: number
  fixedPrice?: number
  minimumRetailPrice?: number
  currencyRatio?: number
  priceCreatedAt: Date
}

export function useApi() {
  const client = useClient()

  async function fetchPriceInfo(itemKey?: string) {
    if (!itemKey) {
      return Promise.resolve(null)
    }
    const { data } = await client.get<IPriceInfo>(
      `/offer/price-calculation-info/${itemKey}`
    )
    return data
  }

  return { fetchPriceInfo }
}
