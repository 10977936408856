import lazyPage from '@monorepo/deprecated/core/hoc/lazyPage'

export default () => [
  {
    path: '/customer-catalog',
    component: lazyPage(() =>
      import('App/View/CustomerCatalog').then(
        ({ CustomerCatalog }) => CustomerCatalog
      )
    ),
    exact: true,
  },
  {
    path: '/customer-catalog2',
    component: lazyPage(() =>
      import('App/View/CustomerCatalog2').then(
        ({ CustomerCatalog }) => CustomerCatalog
      )
    ),
    exact: true,
  },
]
