import React from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import ConditionalWrap from 'conditional-wrap'

import { Link } from 'App/Components/Link'

export interface IPaymentSystem {
  name: string
  icon: string
  href?: string
}

type PaymentItemProps = {
  item: IPaymentSystem
}

export const PaymentItem: React.FC<PaymentItemProps> = ({ item }) => (
  <ConditionalWrap
    condition={Boolean(item.href)}
    wrap={(children: React.ReactNode) => (
      <Link to={item.href!}>{children}</Link>
    )}
  >
    <OverlayTrigger
      // placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {item.name}
        </Tooltip>
      )}
    >
      <div className="rounded bg-light py-1 px-2 ml-1">
        <img src={item.icon} height={20} alt={item.name} />
      </div>
    </OverlayTrigger>
  </ConditionalWrap>
)
