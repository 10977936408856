import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

import { isExternalLink } from '@monorepo/core/helpers/isExternalLink'

interface LinkProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, 'href' | 'ref'> {
  to: string
  children?: React.ReactNode
  external?: boolean
  type?: 'email-' | 'tel-'
}

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  external = isExternalLink(to),
  ...attributes
}) =>
  external ? (
    <a href={to} {...attributes} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <RouterLink to={to} {...attributes}>
      {children}
    </RouterLink>
  )

interface EmailLinkProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, 'href' | 'ref'> {
  email: string
  children?: React.ReactNode
}

export const EmailLink: React.FC<EmailLinkProps> = ({
  email,
  children,
  ...attributes
}) => (
  <a {...attributes} href={`mailto:${email}`}>
    {children ?? email}
  </a>
)

interface PhoneLinkProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, 'href' | 'ref'> {
  phone: string
  children?: React.ReactNode
}

export const PhoneLink: React.FC<PhoneLinkProps> = ({
  phone,
  children,
  ...attributes
}) => (
  <a {...attributes} href={`tel:${phone.replace(/[^+0-9]?/g, '')}`}>
    {children ?? phone}
  </a>
)
