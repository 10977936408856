import React from 'react'

import { Image } from '@monorepo/components/common/Image'
import { Loader as BaseLoader } from '@monorepo/components/common/Loader'
import logoImage from 'App/Resources/images/Logo_territory_mmc.png'

type LoaderProps = {
  //
}

export const Loader: React.FC<LoaderProps> = () => (
  <BaseLoader
    size={250}
    borderSize={5}
    image={<Image loader={false} lazy={false} src={logoImage} />}
    className="m-5"
  />
)
