import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { truncate, uniqueId } from 'lodash'

import { CartItemType } from '@monorepo/deprecated/interfaces'
import { Currency } from '@monorepo/components/common/Currency'
import { Image } from '@monorepo/components/common/Image'

import { Link } from 'App/Components/Link'
import placeholderImage from 'App/Resources/images/placeholder-300x300.png'
import { useItemImages } from 'App/hooks'

type PropsType = {
  item: CartItemType
  removeCartItemHandler(): void
}

export const CartItem: React.FC<PropsType> = ({
  item,
  removeCartItemHandler,
}) => {
  const { thumbs } = useItemImages(item)

  return (
    <li className="dropcart__item">
      <div className="dropcart__item-image">
        <Link to={item.good.searchLink}>
          <Image
            src={(thumbs?.length ?? 0) > 0 ? thumbs : placeholderImage}
            alt=""
            style={{ height: 70, width: 70 }}
            loader={false}
            className="img-thumbnail"
          />
        </Link>
      </div>
      <div className="dropcart__item-info">
        <div className="dropcart__item-name">
          <Link to={item.good.searchLink}>
            {truncate(item.good.description)}
          </Link>
        </div>
        <ul className="dropcart__item-features">
          <li>Производитель: {item.good.brandName}</li>
          <li>Артикул: {item.good.number}</li>
        </ul>
        <div className="dropcart__item-meta">
          <div className="dropcart__item-quantity">{item.amount}</div>
          <div className="dropcart__item-price">
            <Currency value={item.price} />
          </div>
          {item.quality?.id && (
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id={uniqueId('tooltip-')} {...props}>
                  {item.quality?.description}
                </Tooltip>
              )}
            >
              <div className="dropcart__item-price text-danger font-weight-bolder">
                {item.quality?.name}
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>
      <button
        type="button"
        className="dropcart__item-remove mr-0"
        onClick={removeCartItemHandler}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10}>
          <path
            d="M8.8,8.8L8.8,8.8c-0.4,0.4-1,0.4-1.4,0L5,6.4L2.6,8.8c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L3.6,5L1.2,2.6
c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L5,3.6l2.4-2.4c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L6.4,5l2.4,2.4
C9.2,7.8,9.2,8.4,8.8,8.8z"
          />
        </svg>
      </button>
    </li>
  )
}
