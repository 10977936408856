import React from 'react'

import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { useComponentVisible } from '@monorepo/hooks/Util/useComponentVisible'
import { Link } from 'App/Components/Link'
import { MenuPanel } from '../../MenuPanel'
import { LinksList } from '../'

type MenuItemPropsType = {
  item: MenuItemType
}

export const MenuItem: React.FC<MenuItemPropsType> = ({ item }) => {
  const {
    isComponentVisible,
    showComponentHandler,
    hideComponentHandler,
  } = useComponentVisible(false)

  return (
    <li data-mobile-menu-item>
      {item.menu ? (
        <button onClick={showComponentHandler}>
          {item.name}{' '}
          <svg width={7} height={11}>
            <path
              d="M0.3,10.7L0.3,10.7c0.4,0.4,0.9,0.4,1.3,0L7,5.5L1.6,0.3C1.2-0.1,0.7,0,0.3,0.3l0,0c-0.4,0.4-0.4,1,0,1.3l4,3.9l-4,3.9
C-0.1,9.8-0.1,10.4,0.3,10.7z"
            />
          </svg>
        </button>
      ) : (
        <Link to={item.url}>{item.name}</Link>
      )}
      {item.menu && (
        <div className="mobile-menu__links-panel" data-mobile-menu-panel>
          <MenuPanel
            title={item.name}
            hidden={!isComponentVisible}
            onHide={hideComponentHandler}
          >
            <LinksList items={item.menu} />
          </MenuPanel>
        </div>
      )}
    </li>
  )
}
