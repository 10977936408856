import { RequestAction } from 'redux-saga-requests'

import { StateType } from '../records'
import { CarRecord } from '@monorepo/deprecated/core/records'

import { FETCH_CARS, CREATE_CAR } from '../constants'

export function createCarAction(): RequestAction {
  return {
    type: CREATE_CAR,
    request: false,
    meta: {
      mutations: {
        [FETCH_CARS]: {
          updateData({ data }: StateType) {
            const car = CarRecord()

            return data.update('cars', (cars) => cars.unshift(car))
          },
          local: true,
        },
      },
    },
  }
}
