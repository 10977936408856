import React from 'react'

// import { Link } from 'App/Components/Link'
import { ErrorLayout } from './ErrorLayout'

type Props = {
  //
}

export const Error404: React.FC<Props> = () => (
  <ErrorLayout code="404" title="Страница не найдена">
    <p>Запрашиваемая страница не найдена или удалена.</p>
  </ErrorLayout>
)
