import qs from 'qs'
import { List, Record, RecordOf } from 'immutable'
import { castArray, get, isEmpty } from 'lodash'

import { GoodType, BrandType } from '@monorepo/deprecated/interfaces'
import { makeListNew } from './CollectionFactory'
import { BrandRecord } from './BrandRecord'

export type GoodRecordPropsType = GoodType & {}

const defaultValues: GoodRecordPropsType = {
  id: '',
  brandName: '',
  brand: BrandRecord(),
  number: '',
  name: '',
  description: '',
  catalogLink: '',
  searchLink: '',
  images: undefined,
  thumbs: undefined,
  offers: undefined,
}

export type GoodRecord = RecordOf<GoodRecordPropsType>
export type GoodRecordsCollection = List<GoodRecord>

const makeGoodRecord: Record.Factory<GoodRecordPropsType> = Record(
  defaultValues,
  'GoodRecord'
)

const createCatalogLink = (
  brand: BrandType | undefined,
  number: string | undefined
): string | undefined =>
  brand && number ? `/goods/${brand.name}/${number}` : undefined

const createSearchLink = (
  brand: BrandType | undefined,
  number: string | undefined
): string | undefined =>
  brand && number
    ? `/search${qs.stringify(
        { brandName: brand.name, number },
        { addQueryPrefix: true }
      )}`
    : undefined

const mapper = ({
  brand,
  description,
  images,
  name,
  ...props
}: Partial<GoodType> = {}): Partial<GoodRecordPropsType> => ({
  brand: BrandRecord(brand),
  brandName: brand?.name,
  description: name,
  name: `${get(brand, 'name')} ${props.number}`,
  catalogLink: createCatalogLink(brand, props.number),
  searchLink: createSearchLink(brand, props.number!),
  images: isEmpty(images) ? undefined : castArray(images),
  ...props,
})

export const GoodRecord = (props: Partial<GoodType> = {}): GoodRecord =>
  makeGoodRecord(mapper(props))

export const GoodRecordsCollection = makeListNew<GoodRecord, GoodType>(
  GoodRecord
)
