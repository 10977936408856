import { RequestAction } from 'redux-saga-requests'

import { CarType } from '@monorepo/deprecated/interfaces'
import { StateType, removeCar } from '../records'
import { CarRecord } from '@monorepo/deprecated/core/records'

import { FETCH_CARS, REMOVE_CAR } from '../constants'

type RemoveCarActionType = RequestAction & {
  meta: {
    car: CarType
    position?: number
  }
}
export function removeCarAction(car: CarType): RemoveCarActionType {
  return {
    type: REMOVE_CAR,
    request: car.isNew
      ? false
      : {
          url: '/cars/remove',
          method: 'post',
          data: car,
        },
    meta: {
      car,
      mutations: {
        [FETCH_CARS]: {
          updateDataOptimistic(
            { data }: StateType,
            { meta }: RemoveCarActionType
          ) {
            const {
              car: { id: carId },
            } = meta

            meta.position = data.cars.findIndex((car) => car.id === carId)
            return data.update('cars', (carsList) => removeCar(carsList, carId))
          },
          updateData({ data }: StateType) {
            return data
          },
          revertData({ data }: StateType, { meta }: RemoveCarActionType) {
            const { position } = meta
            return data.update('cars', (carsList) =>
              carsList.insert(position || 0, CarRecord(car))
            )
          },
          local: car.isNew,
        },
      },
    },
  }
}
