import React from 'react'
import cn from 'classnames'

import { SearchField } from 'App/Components/SearchField'

type SearchPropsType = {
  ref?: React.Ref<HTMLDivElement>
  show?: boolean
  onHide?(): void
}

export const Search: React.FC<SearchPropsType> = React.forwardRef<
  HTMLDivElement,
  SearchPropsType
>(({ show = false, onHide }, ref) => (
  <div
    className={cn('mobile-header__search', 'mobile-search', {
      'mobile-header__search--open': show,
    })}
    ref={ref}
  >
    {true ? (
      <SearchField mobile onHide={onHide} />
    ) : (
      <form className="mobile-search__body">
        <input
          type="text"
          className="mobile-search__input"
          placeholder="Enter keyword or part number"
        />
        <button
          type="submit"
          className="mobile-search__button mobile-search__button--search"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}>
            <path
              d="M19.2,17.8c0,0-0.2,0.5-0.5,0.8c-0.4,0.4-0.9,0.6-0.9,0.6s-0.9,0.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9C10.9,14.5,9.5,15,8,15
c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7c0,1.5-0.5,2.9-1.3,4c1.1,0.8,2.5,2,4,3.1C20,16.8,19.2,17.8,19.2,17.8z M8,3C5.2,3,3,5.2,3,8
c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C13,5.2,10.8,3,8,3z"
            />
          </svg>
        </button>
        <button
          type="button"
          className="mobile-search__button mobile-search__button--close"
          onClick={onHide}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20}>
            <path
              d="M16.7,16.7L16.7,16.7c-0.4,0.4-1,0.4-1.4,0L10,11.4l-5.3,5.3c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4L8.6,10L3.3,4.7
c-0.4-0.4-0.4-1,0-1.4l0,0c0.4-0.4,1-0.4,1.4,0L10,8.6l5.3-5.3c0.4-0.4,1-0.4,1.4,0l0,0c0.4,0.4,0.4,1,0,1.4L11.4,10l5.3,5.3
C17.1,15.7,17.1,16.3,16.7,16.7z"
            />
          </svg>
        </button>
        <div className="mobile-search__field" />
      </form>
    )}
  </div>
))

// const Se = () => (
//   <form className={className} ref={componentRef}>
//     <div className="search__shadow" />
//     <input
//       className="search__input"
//       type="text"
//       placeholder="Номер запчасти..."
//       onChange={event => handleChange(event.target.value)}
//       value={query}
//       onClick={handleClick}
//       // onBlur={hideComponentHandler}
//     />
//     <button className="search__button search__button--end" type="submit">
//       <span className="search__button-icon">
//         <FontAwesomeIcon
//           icon={loading ? faCircleNotch : faSearch}
//           spin={loading}
//         />
//       </span>
//     </button>
//     <SuggestionsList
//       height="50vh"
//       open={isComponentVisible}
//       items={suggestions}
//       onSelect={handleSelect.bind(null)}
//     />
//     {showVehicleSelector && <VehicleButton />}

//     <div className="search__box" />
//     <div className="search__decor">
//       <div className="search__decor-start" />
//       <div className="search__decor-end" />
//     </div>

//     {showVehicleSelector && <VehiclePicker />}
//   </form>
// )
