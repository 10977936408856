import { Action } from 'redux'
import { RequestAction } from 'redux-saga-requests'

import { UserCredentialsType } from '@monorepo/deprecated/interfaces'

import {
  AUTHENTICATE,
  INITIALIZE,
  SET_TOKENS,
  CLEAR_TOKENS,
  SET_SWITCH_USER,
  LOGOUT,
} from './constants'

export function initializeAction(): Action {
  return {
    type: INITIALIZE,
  }
}

type TokensType = {
  token?: string
  refreshToken?: string
}

export type SetTokensActionType = {
  type: typeof SET_TOKENS
  meta: { payload: TokensType }
}

export function setTokensAction(tokens: TokensType): SetTokensActionType {
  return {
    type: SET_TOKENS,
    meta: {
      payload: tokens,
    },
  }
}

export function clearTokensAction(): Action {
  return {
    type: CLEAR_TOKENS,
  }
}

export function authenticateAction(
  credentials: UserCredentialsType
): RequestAction {
  return {
    type: AUTHENTICATE,
    request: {
      url: '/auth/login',
      method: 'POST',
      data: credentials,
    },
    meta: {
      asPromise: true,
    },
  }
}

export function switchUserAction(user: any) {
  return {
    type: SET_SWITCH_USER,
    meta: {
      payload: user,
    },
  }
}

export function logoutAction(): Action {
  return {
    type: LOGOUT,
  }
}
