import lazyPage from '@monorepo/deprecated/core/hoc/lazyPage'

export default () => [
  {
    path: '/original-catalogs/:name',
    component: lazyPage(() =>
      import('App/View/OriginalCatalogs').then(
        ({ OriginalCatalogs }) => OriginalCatalogs
      )
    ),
    exact: true,
  },
  {
    path: '/original-catalogs',
    component: lazyPage(() =>
      import('App/View/OriginalCatalogs').then(
        ({ OriginalCatalogs }) => OriginalCatalogs
      )
    ),
    exact: true,
  },
]
