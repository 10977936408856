import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { setLogger } from 'react-query'

import { debug, sentryDSN } from '@monorepo/core/config'
// Sentry.setUser({ email: 'john.doe@example.com', name: 'hhfhfh hhhhfhhf' })
Sentry.init({
  dsn: sentryDSN,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 1.0,
  tracesSampler: () => (debug ? false : 0.5),

  beforeSend(event, hint) {
    // console.log({ event, hint })
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: { email: '', name: '' },
        lang: 'ru',
        title: 'Упс! Произошла ошибка!',
        subtitle: 'Наши специалисты уже работают над её решением.',
        subtitle2:
          'Вы можете сообщить дополнительную информацию об обстоятельствах её возникновения.',
        labelName: 'Ваше имя',
        labelEmail: 'Ваш Email',
        labelComments: 'Что произошло',
        labelClose: 'Закрыть',
        labelSubmit: 'Отправить',
        successMessage: 'Ваше сообщение отправлено. Благодарим за содействие!',
      })
    }
    return event
  },
})

// Sentry logger
setLogger({
  log: (message) => {
    Sentry.captureMessage(message)
  },
  warn: (message) => {
    Sentry.captureMessage(message)
  },
  error: (error) => {
    Sentry.captureException(error)
  },
})
