import React from 'react'

import { MenuType } from '@monorepo/deprecated/interfaces'
import { MenuItemsList } from './MenuItemsList'

type MenuPropsType = {
  items: MenuType
}

export const Menu: React.FC<MenuPropsType> = ({ items }) => (
  <div className="header__navbar-menu">
    <div className="main-menu">
      <MenuItemsList items={items} />
    </div>
  </div>
)
