import { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { htmlParser } from '@monorepo/core/helpers'
import {
  fetchPageAction,
  clearPageAction,
  getBlockSelector,
} from '@monorepo/deprecated/core/features/PageBlock'

export function useContent(pageSlug?: string) {
  const dispatch = useDispatch()
  const { slug: urlSlug } = useParams<{ slug?: string }>()
  const slug = pageSlug ?? urlSlug
  const getBlock = useSelector(getBlockSelector)
  const { data: page, loading } = getBlock(slug)

  const fetchPageHandler = useCallback(
    bindActionCreators(fetchPageAction, dispatch),
    []
  )

  const clearPageHandler = useCallback(
    bindActionCreators(clearPageAction, dispatch),
    []
  )

  useEffect(() => {
    slug && fetchPageHandler(slug)
    return () => {
      slug && clearPageHandler(slug)
    }
  }, [slug, clearPageHandler, fetchPageHandler])

  return { page, loading, htmlParser }
}
