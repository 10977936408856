import React from 'react'

import { PaymentsList, IPaymentSystem } from './PaymentsList'

import mirLogo from 'App/Resources/images/payment-systems/mir-logo_original.svg'
import visaLogo from 'App/Resources/images/payment-systems/visa-logo_original.svg'
import masterCardLogo from 'App/Resources/images/payment-systems/mastercard-logo_original.svg'
// import qiwiLogo from 'App/Resources/images/payment-systems/qiwi-wallet-logo_original.svg'

type BottomPropsType = {
  //
}

const paymentSystems: IPaymentSystem[] = [
  {
    name: 'Карта Мир',
    icon: mirLogo,
    href: '/pages/kak_oplatit_zazkaz',
  },
  {
    name: 'Карта Visa',
    icon: visaLogo,
    href: '/pages/kak_oplatit_zazkaz',
  },
  {
    name: 'Карта MasterCard',
    icon: masterCardLogo,
    href: '/pages/kak_oplatit_zazkaz',
  },
  // {
  //   name: 'QIWI Кошелёк',
  //   icon: qiwiLogo,
  // },
]

export const Bottom: React.FC<BottomPropsType> = () => (
  <div className="site-footer__bottom">
    <div className="container">
      <div className="site-footer__bottom-row">
        <div className="site-footer__copyright">
          {/* copyright */}
          Powered by&nbsp;&nbsp;&nbsp;
          <a
            title="Разработка интернет-магазинов автозапчастей"
            href="https://itonix.ru"
            rel="noopener"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <img
              src="https://itonix.ru/wp-content/uploads/2020/01/logo_itonix_gray_300px.png"
              width="100"
              alt="Разработка интернет-магазинов автозапчастей. Программа учета в магазине автозапчастей."
            />
          </a>
          {/* copyright / end */}
        </div>
        <PaymentsList title="Принимаем к оплате" items={paymentSystems} />
        {/* <div className="site-footer__payments">
          <img src="images/payments.png" alt="" />
        </div> */}
      </div>
    </div>
  </div>
)
