import React from 'react'
import cn from 'classnames'

import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { MenuItem } from './MenuItem'

type MenuItemsListPropsType = {
  items: Array<MenuItemType>
  level?: number
}

export const MenuItemsList: React.FC<MenuItemsListPropsType> = ({
  items,
  level = 0,
}) => (
  <ul
    className={cn(
      {
        'main-menu__list': level === 0,
      },
      {
        menu: level > 0,
      }
    )}
  >
    {items.map((item, idx) => (
      <MenuItem key={idx} item={item} level={level} />
    ))}
  </ul>
)
