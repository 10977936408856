import { Record, RecordOf } from 'immutable'
import { parseISO } from 'date-fns'

import { CompanyType } from '@monorepo/deprecated/interfaces'
import { AddressRecord } from '../AddressRecord'
import { OfficeRecordsCollection } from './OfficeRecord'

export type CompanyRecordPropsType = Omit<
  CompanyType,
  'address' | 'offices'
> & {
  address: AddressRecord
  offices: OfficeRecordsCollection
}

const defaultValues: CompanyRecordPropsType = {
  // id: undefined,
  name: '',
  email: '',
  site: undefined,
  foundationDate: undefined,
  address: AddressRecord(),
  comment: undefined,
  phones: [],
  socialNetworks: [],
  messagers: {},
  mapPlace: undefined,
  businessHours: undefined,
  offices: OfficeRecordsCollection(),
}

export type CompanyRecord = RecordOf<CompanyRecordPropsType>

const makeCompanyRecord: Record.Factory<CompanyRecordPropsType> = Record(
  defaultValues,
  'CompanyRecord'
)

const mapper = ({
  address,
  foundationDate,
  offices,
  ...props
}: Partial<CompanyType>): Partial<CompanyRecordPropsType> => ({
  address: AddressRecord(address),
  foundationDate: foundationDate ? parseISO(String(foundationDate)) : undefined,
  offices: OfficeRecordsCollection(offices),
  ...props,
})

export const CompanyRecord = (
  props: Partial<CompanyType> = {}
): CompanyRecord => makeCompanyRecord(mapper(props))
