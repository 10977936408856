import { requestsReducer } from 'redux-saga-requests'

import {
  CLEAR_CART,
  FETCH_CART,
  POPULATE_CART,
  // CREATE_CART_ITEM,
  // UPDATE_CART_ITEM,
  REMOVE_CART_ITEM,
} from './constants'
// import { CartItemsCollection } from './records'
import { StateType } from './types'
import { CartItemRecordsCollection } from '@monorepo/deprecated/core/records'

export const cartReducer = requestsReducer({
  // multiple: true,
  actionType: FETCH_CART,
  resetOn: [CLEAR_CART],
  // getDefaultData: () => CartItemsCollection(),
  getDefaultData: () => CartItemRecordsCollection(),
  // getData: (state, action, config) => populateCart(action.payload.data),
  mutations: {
    //   [CREATE_CART_ITEM]: {
    //     updateDataOptimistic: (state, action) => {
    //       const cartItem = CartItemRecord(action.meta.cartItem)
    //       return addToCart(state.data, cartItem)
    //     },
    //     updateData: (state, action) => {
    //       return updateCartItem(state.data, action.meta.cartItem, {
    //         id: action.data.id,
    //       })
    //     },
    //     revertData: (state, action) => {
    //       const cartItem = CartItemRecord(action.meta.cartItem)
    //       return removeFromCart(state.data, cartItem)
    //     },
    //     getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
    //   },
    //   [UPDATE_CART_ITEM]: {
    //     updateDataOptimistic: (state, action) => {
    //       return updateCartItem(
    //         state.data,
    //         action.meta.cartItem,
    //         action.meta.changes
    //       )
    //     },
    //     revertData: (state, action) => {
    //       const cartItem = CartItemRecord(action.meta.cartItem)
    //       return updateCartItem(
    //         state.data,
    //         cartItem,
    //         pick(cartItem, keys(action.meta.changes))
    //       )
    //     },
    //   },
    [REMOVE_CART_ITEM]: {
      // updateDataOptimistic: (state, action) => {
      //   return removeFromCart(state.data, action.meta.cartItem)
      // },
      // revertData: (state, action) => {
      //   const cartItem = CartItemRecord(action.meta.cartItem)
      //   return addToCart(state.data, cartItem)
      // },
      updateData: (state: StateType, action) => {
        const cartItem = action.meta.cartItem
        return state.data.filter((item) => item.id !== cartItem.id)
      },
      // local: true,
    },
    [POPULATE_CART]: {
      // updateData: (state, { meta }) => CartItemsCollection(meta.items),
      updateData: (state, { meta }) => CartItemRecordsCollection(meta.items),
      local: true,
    },
  },
})

// export default reducer
