import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router/immutable'
import { createBrowserHistory } from 'history'
import {
  requestsCacheMiddleware,
  requestsPromiseMiddleware,
} from 'redux-saga-requests'
import { connectRouter } from 'connected-react-router'
import { networkReducer } from 'redux-saga-requests'

import { debug } from '@monorepo/core/config'
import { reducer, saga as rootSaga } from '@monorepo/deprecated/core/features'

// import { composeWithDevTools } from 'redux-devtools-extension'
import middleware from './middleware'
// import { createReducerManager } from './createReducerManager'

export const history = createBrowserHistory()

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    network: networkReducer({}),
    ...reducer,
  })

export default function configureStore(initialState = {} /*, history*/) {
  let composeEnhancers = compose

  if (debug) {
    const Immutable = require('immutable')
    const devToolsOptions = {
      trace: true,
      serialize: {
        immutable: Immutable,
      },
    }
    const composeWithDevTools = require('redux-devtools-extension')
      .composeWithDevTools
    composeEnhancers = composeWithDevTools(devToolsOptions)
  }

  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

  const middlewares = [
    requestsCacheMiddleware(),
    requestsPromiseMiddleware(/*{ auto: true }*/),
    sagaMiddleware,
    routerMiddleware(history),
    ...middleware,
  ]

  const enhancers = [applyMiddleware(...middlewares)]

  // const reducerManager = createReducerManager({
  //   router: connectRouter(history),
  //   network: networkReducer({}),
  //   ...reducer,
  // })

  const store = createStore(
    // reducerManager.reduce,
    createRootReducer(history),
    initialState,
    composeEnhancers(...enhancers)
  )

  sagaMiddleware.run(rootSaga)

  // Extensions
  // store.reducerManager = reducerManager
  store.runSaga = sagaMiddleware.run
  // store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  return store
}
