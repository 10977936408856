import { parseISO } from 'date-fns'

import { useClient } from '@monorepo/core/api_module'
import { ReconciliationType } from '@monorepo/deprecated/interfaces'
import {
  FetchReconciliationsFilterType,
  FetchReconciliationsResponseType,
} from './interfaces'

export const useReconciliationsApi = () => {
  const client = useClient()

  const fetchList = async (
    // _endPoint: string,
    filter: FetchReconciliationsFilterType
  ) => {
    const endPoint = `/v2/contracts/${filter.contractNumber}/show`

    const {
      data: { items, ...meta },
    } = await client.get<FetchReconciliationsResponseType>(endPoint, {
      params: filter,
    })

    return {
      ...meta,
      items: items?.map<ReconciliationType>(({ createdAt, ...item }) => ({
        createdAt: parseISO(createdAt),
        isExpanded: false,
        ...item,
      })),
      // .sort(({ createdAt: a }, { createdAt: b }) =>
      //   differenceInSeconds(b, a)
      // ),
    }
  }

  return { fetchList }
}
