import { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { VendorType } from '@monorepo/deprecated/interfaces'
import {
  deliveryStatisticsSelector,
  clearDeliveryStatisticsAction,
  fetchDeliveryStatisticsAction,
} from '@monorepo/deprecated/core/features/DeliveryStatistics'

export function useDeliveryStatistics(vendor?: VendorType) {
  const { data: statistics, loading } = useSelector(deliveryStatisticsSelector)

  const dispatch = useDispatch()
  const clear = useCallback(
    bindActionCreators(clearDeliveryStatisticsAction, dispatch),
    []
  )
  const fetch = useCallback(
    (vendor: VendorType) => {
      dispatch(fetchDeliveryStatisticsAction({ vendor }))
    },
    [dispatch]
  )

  useEffect(() => {
    vendor && fetch(vendor)
    return () => {
      vendor && !isEmpty(statistics) && clear()
    }
  }, [clear, fetch, statistics, vendor])

  return { statistics, loading, fetch, clear }
}
