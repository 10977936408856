import { QueryState } from 'redux-saga-requests'

import { makeMapNew } from '@monorepo/deprecated/core/records/CollectionFactory'
import { Map } from 'immutable'

import { PageBlockType } from '@monorepo/deprecated/interfaces'
import { PageBlockRecord } from './page-block.record'

export const BlockStateRecord = makeMapNew<PageBlockRecord, PageBlockType>(
  PageBlockRecord,
  'slug'
)

export type BlockStateRecord = Map<string, PageBlockRecord>

export type StateType = QueryState<BlockStateRecord>
