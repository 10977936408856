import React from 'react'

import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { Link } from 'App/Components/Link'

type MenuItemProps = {
  item: MenuItemType
}

export const MenuItem: React.FC<MenuItemProps> = ({ item }) => (
  <div className="topbar__item-text">
    <Link className="topbar__link" to={item.url}>
      {item.name}
    </Link>
  </div>
)
