import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { memoize } from 'lodash'

import groupResult from './groupResult'

// import { fetchOffers } from '../actions'
import { GoodRecordsCollection } from '@monorepo/deprecated/core/records'
import { FETCH_SUGGESTIONS, FETCH_SOURCE } from '../constants'
import { DataRecord, DataType } from '../records'
import { cartSelector } from '@monorepo/deprecated/core/features/cart'
import { OptionsType } from '../types'

// import { cartSelector } from '@monorepo/deprecated/core/features/cart/selectors'
// import { ReducerRecord } from '../records'

// export const requestSelector = getQuery(fetchOffers())

// export const remoteVendorsSelector = createSelector(
//   requestSelector,
//   ({ data }) => (data || ReducerRecord()).get('remoteVendors')
// )
import { CartItemType, OfferType } from '@monorepo/deprecated/interfaces'
import { curryRight, flow, filter, map, sumBy, uniqBy } from 'lodash/fp'

const cartItemsCalculator = curryRight(
  (offer: OfferType, cartItems: CartItemType[]) =>
    flow(filter({ itemKey: offer.itemKey }), sumBy('amount'))(cartItems)
)

const proccess = (offers: OfferType[], cartItems: CartItemType[]) => {
  const countCartItems = cartItemsCalculator(cartItems)
  return flow(
    uniqBy<OfferType>('itemKey'),
    map<OfferType, OfferType>((offer) => ({
      ...offer,
      cartAmount: countCartItems(offer),
    }))
  )(offers)
}

// const processor = flow(
//   uniqBy<OfferType>('itemKey'),
//   map<OfferType, OfferType>(offer => ({
//     ...offer,
//     cartAmount: countCartItems(offer),
//   }))
// )

export const resultSelector = createSelector(
  getQuery<DataRecord>({
    type: FETCH_SOURCE,
    defaultData: DataRecord(),
  }),
  cartSelector,
  ({ data: result, ...state }, { data: cartItems }) => {
    const { offers, ...data } = result.toJS()

    return {
      ...state,
      data: {
        offers: proccess(offers, cartItems),
        ...data,
      } as DataType,
    }
  }
)

export const suggestionsSelector = createSelector(
  getQuery<GoodRecordsCollection>({
    type: FETCH_SUGGESTIONS,
    defaultData: GoodRecordsCollection(),
  }),
  ({ data, ...state }) => {
    return {
      ...state,
      data: data.toJS(),
    }
  }
)

export const getGroupResultSelector = createSelector(
  resultSelector,
  cartSelector,
  ({ data: result, ...state }, { data: cartItems }) =>
    memoize((options?: Partial<OptionsType>) => ({
      ...state,
      data: groupResult(result, options),
      isEmpty: result.offers.length === 0,
      loaded: result.loaded,
    }))
)
