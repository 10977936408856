import { Record, RecordOf } from 'immutable'

import { PaginationMetaType } from '@monorepo/deprecated/interfaces'

export type PaginatorRecordPropsType = PaginationMetaType & {}

const defaultValues: PaginatorRecordPropsType = {
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  perPage: 0,
}

export type PaginatorRecord = RecordOf<PaginatorRecordPropsType>

const makePaginatorRecord: Record.Factory<PaginatorRecordPropsType> = Record(
  defaultValues,
  'PaginatorRecord'
)

const mapper = ({
  ...props
}: Partial<PaginationMetaType>): Partial<PaginatorRecordPropsType> => ({
  ...props,
})

export const PaginatorRecord = (
  props: Partial<PaginationMetaType> = {}
): PaginatorRecord => makePaginatorRecord(mapper(props))
