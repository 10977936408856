import { getQuery } from 'redux-saga-requests'
import { get } from 'lodash'

import { MODULE } from './constants'
import { StateType } from './types'
import { CompanyType } from '@monorepo/deprecated/interfaces'
// import { CompanyRecord } from 'packages/core/records'

export const companySelector = getQuery<CompanyType>({
  requestSelector: (state: StateType) => {
    const { data, ...rest } = get(state, MODULE, {})

    return {
      ...rest,
      // data: (data as CompanyRecord)?.toJS(),
      data: data?.toJS(),
    }
  },
})

// import { createSelector } from 'reselect'
// import makeStateSelector from '@monorepo/core/utils/makeStateSelector'

// import { MODULE } from './constants'
// import { ReducerRecord } from './records'

// const stateSelector = makeStateSelector(MODULE, ReducerRecord)

// export const companySelector = createSelector(stateSelector, state =>
//   state.data.toJS()
// )
