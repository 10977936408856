import React from 'react'
import cn from 'classnames'

import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { Link } from 'App/Components/Link'
import { MenuItemsList } from '../MenuItemsList'
import { ArrowDownIcon } from './ArrowDownIcon'
import { ArrowRightIcon } from './ArrowRightIcon'

type MenuItemPropsType = {
  item: MenuItemType
  level?: number
}

export const MenuItem: React.FC<MenuItemPropsType> = ({ item, level = 0 }) => (
  <li
    className={cn(
      {
        'main-menu__item': level === 0,
        'main-menu__item--submenu--menu': level === 0 && item.menu,
        'main-menu__item--has-submenu': level === 0 && item.menu,
      },
      {
        menu__item: level > 0,
        'menu__item--has-submenu': level > 0 && item.menu,
      }
    )}
  >
    <Link
      to={item.url}
      external={item.external}
      className={cn(
        { 'main-menu__link': level === 0 },
        { menu__link: level > 0 }
      )}
    >
      {item.name}
      {item.menu &&
        (level === 0 ? (
          <ArrowDownIcon />
        ) : (
          level === 1 && (
            <span className="menu__arrow">
              <ArrowRightIcon />
            </span>
          )
        ))}
    </Link>
    {item.menu && (
      <div className={level === 0 ? 'main-menu__submenu' : 'menu__submenu'}>
        {<MenuItemsList items={item.menu} level={level + 1} />}
      </div>
    )}
  </li>
)
