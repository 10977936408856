/* eslint-disable no-undef */
import React from 'react'
import { Helmet } from 'react-helmet'
import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from 'react-query-devtools'
import * as Sentry from '@sentry/react'

import { Metrics } from '@monorepo/components/common/Metrics'
import { debug } from '@monorepo/core/config'
import { Layout } from './Layout'
import { Error500 } from 'App/View/Errors'

import './Resources/styles/style.scss'
// const dialogOptions = {
//   lang: 'ru',
//   user: {
//     email: '',
//     name: '',
//   },
//   title: 'Упс! Произошла ошибка!',
//   subtitle: 'Наши специалисты уже работают над её решением.',
//   subtitle2:
//     'Вы можете сообщить дополнительную информацию об обстоятельствах её возникновения.',
//   labelName: 'Ваше имя',
//   labelEmail: 'Ваш Email',
//   labelComments: 'Что произошло',
//   labelClose: 'Закрыть',
//   labelSubmit: 'Отправить',
//   successMessage: 'Ваше сообщение отправлено. Благодарим за содействие!',
// }
export const App = Sentry.withProfiler(
  ({ routes, location, locale, ...props }) => (
    <>
      <Helmet
        encodeSpecialCharacters
        titleTemplate="%s | Территория Митсубиши (Mitsubishi) - Интернет-магазин автозапчастей"
        defaultTitle="Территория Митсубиши (Mitsubishi) - Интернет-магазин автозапчастей"
      >
        <html dir="ltr" lang={locale} amp />
        <meta charSet="utf-8" />
      </Helmet>
      {/* {console.log(locale)} */}
      <Sentry.ErrorBoundary
        fallback={Error500}
        // showDialog
        // dialogOptions={{ ...dialogOptions, title: '1' }}
      >
        <Layout>
          <Sentry.ErrorBoundary
            fallback={Error500}
            // showDialog
            // dialogOptions={{ ...dialogOptions, title: '2' }}
          >
            {renderRoutes(routes, props, { location })}
          </Sentry.ErrorBoundary>
        </Layout>
      </Sentry.ErrorBoundary>
      <ToastContainer />
      {!debug && <Metrics />}
      {debug && <ReactQueryDevtools />}
    </>
  )
)
