import { List, Record, RecordOf } from 'immutable'

import { OfficeType } from '@monorepo/deprecated/interfaces'
import { makeListNew } from '../CollectionFactory'
import { AddressRecord } from '../AddressRecord'

export type OfficeRecordPropsType = Omit<OfficeType, 'address'> & {
  address: AddressRecord
}

const defaultValues: OfficeRecordPropsType = {
  id: '',
  name: '',
  email: '',
  address: AddressRecord(),
  comment: undefined,
  phones: [],
  socialNetworks: [],
  messagers: {},
  mapPlace: undefined,
  businessHours: undefined,
  isSalesPoint: false,
}

export type OfficeRecord = RecordOf<OfficeRecordPropsType>
export type OfficeRecordsCollection = List<OfficeRecord>

const makeOfficeRecord: Record.Factory<OfficeRecordPropsType> = Record(
  defaultValues,
  'OfficeRecord'
)

const mapper = ({
  address,
  ...props
}: Partial<OfficeType>): Partial<OfficeRecordPropsType> => ({
  address: address ? AddressRecord(address) : undefined,
  ...props,
})

export const OfficeRecord = (props: Partial<OfficeType> = {}): OfficeRecord =>
  makeOfficeRecord(mapper(props || {}))

export const OfficeRecordsCollection = makeListNew<OfficeRecord, OfficeType>(
  OfficeRecord
)
