import { Action } from 'redux'
import { RequestAction } from 'redux-saga-requests'

import { VendorType } from '@monorepo/deprecated/interfaces'
import { FETCH_INFO, CLEAR_INFO } from './constants'
import { StateType } from './types'

type FetchDeliveryStatisticsActionProps = {
  vendor: VendorType
}
export function fetchDeliveryStatisticsAction({
  vendor,
}: FetchDeliveryStatisticsActionProps): RequestAction {
  return {
    type: FETCH_INFO,
    request: {
      url: `/vendor/delivery/statistics/${vendor.id}`,
    },
    meta: {
      resetOn: [CLEAR_INFO],
      cache: 60,
      cacheKey: String(vendor.id),
      getData: (_state, action) => {
        return action.data as StateType
      },
    },
  }
}

export function clearDeliveryStatisticsAction(): Action {
  return {
    type: CLEAR_INFO,
  }
}
