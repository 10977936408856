import { useCallback, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'

import { getCarSchema } from '@monorepo/validations'
import { CarType } from '@monorepo/deprecated/interfaces'
import {
  fetchBrandsAction,
  fetchModelsAction,
} from '@monorepo/deprecated/core/features/CarCatalog'

export function useCarForm(car: CarType) {
  const initialValues = {
    mark: car.mark || '',
    model: car.model || '',
    modification: car.modification || '',
    description: car.description || '',
    productionYear: car.productionYear || undefined,
    engineModel: car.engineModel || '',
    enginePower: car.enginePower || '',
    engineSize: car.engineSize || '',
    vin: car.vin || '',
  }

  const dispatch = useDispatch()

  const fetchBrands = useCallback(
    bindActionCreators(fetchBrandsAction, dispatch),
    []
  )

  const fetchModels = useCallback(
    bindActionCreators(fetchModelsAction, dispatch),
    []
  )

  const validationSchema = useMemo(() => getCarSchema(), [])

  return { initialValues, validationSchema, fetchBrands, fetchModels }
}
