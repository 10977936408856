import { RequestAction } from 'redux-saga-requests'

import { DataRecord, StateType } from '../records'
import { CarRecordsCollection } from '@monorepo/deprecated/core/records'

import { FETCH_CARS, CLEAR_CARS } from '../constants'

export function fetchCarsAction(): RequestAction {
  return {
    type: FETCH_CARS,
    request: {
      url: '/cars',
    },
    meta: {
      resetOn: [CLEAR_CARS],
      //     asPromise: true,
      getData(state: StateType, action) {
        return DataRecord({ cars: CarRecordsCollection(action.data) })
      },
    },
  }
}
