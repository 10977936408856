import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useCallback, useMemo } from 'react'

import { getResetPasswordRequestSchema } from '@monorepo/validations/ResetPasswordRequestSchema'
// import { UserCredentialsType } from '@monorepo/deprecated/interfaces'
import { resetPasswordRequestAction } from '@monorepo/deprecated/core/features/user/actions'
// import { useUser } from './useUser'

type ResetPasswordRequestType = {
  email: string
}

export function useResetPasswordRequestForm() {
  const initialValues: ResetPasswordRequestType = useMemo(
    () => ({
      email: '',
    }),
    []
  )

  const dispatch = useDispatch()
  const resetPasswordRequest = useCallback(
    bindActionCreators(resetPasswordRequestAction, dispatch),
    []
  )

  const onSubmit = useCallback(
    async (values: ResetPasswordRequestType) => {
      try {
        return await resetPasswordRequest(values)
      } catch (error) {
        const errorMessage =
          error?.error?.response?.data?.errors ??
          `Пользователь с адресом «${values.email}» не найден`

        throw new Error(errorMessage)
      }
    },
    [resetPasswordRequest]
  )
  // const onSubmit = useCallback(
  //   async (credentails: UserCredentialsType) => {
  //     try {
  //       await loginHandler(credentails)
  //     } catch (error) {
  //       throw new Error('Неверный логин или пароль')
  //     }
  //   },
  //   [loginHandler]
  // )

  const validationSchema = useMemo(() => getResetPasswordRequestSchema(), [])

  return { validationSchema, initialValues, onSubmit }
}
