import { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

import {
  modelsSelector,
  clearModelsAction,
  fetchModelsAction,
} from '@monorepo/deprecated/core/features/CarCatalog'

export function useModels() {
  const { data: models, loading } = useSelector(modelsSelector)

  const dispatch = useDispatch()

  const fetchModels = useCallback(
    debounce((brand: string, name: string) => {
      dispatch(fetchModelsAction({ brand, name }))
    }, 1000),
    [dispatch]
  )
  const clearModels = useCallback(
    bindActionCreators(clearModelsAction, dispatch),
    []
  )

  useEffect(() => {
    return () => {
      clearModels()
    }
  }, [clearModels])

  return { fetchModels, models, loading }
}
