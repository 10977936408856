import { List, Record, RecordOf } from 'immutable'

import { CartItemType } from '@monorepo/deprecated/interfaces'
import { makeListNew } from './CollectionFactory'

import { ContractRecord } from './ContractRecord'
import { GoodRecord } from './GoodRecord'
import { OfferRecord } from './OfferRecord'
import { VendorRecord } from './VendorRecord'

export type CartItemRecordPropsType = CartItemType & {}

const defaultValues: CartItemRecordPropsType = {
  amount: 0,
  contract: undefined,
  deliveryTime: 0,
  good: GoodRecord(),
  id: undefined,
  isReturnAllowed: false,
  isSelected: true,
  isStock: false,
  stockName: undefined,
  itemKey: '',
  name: '',
  offer: null,
  price: 0,
  reference: '',
  returnPercent: 0,
  vendor: VendorRecord(),
  quality: undefined,
  images: undefined,
  thumbs: undefined,
}

export type CartItemRecord = RecordOf<CartItemRecordPropsType>
export type CartItemRecordsCollection = List<CartItemRecord>

const makeCartItemRecord: Record.Factory<CartItemRecordPropsType> = Record(
  defaultValues,
  'CartItemRecord'
)

const mapper = ({
  contract,
  good,
  offer,
  vendor,
  ...props
}: Partial<CartItemType>): Partial<CartItemRecordPropsType> => ({
  ...props,
  contract: ContractRecord(contract),
  good: GoodRecord(good),
  offer: offer ? OfferRecord(offer) : null,
  vendor: VendorRecord(vendor),
})

export const CartItemRecord = (
  props: Partial<CartItemType> = {}
): CartItemRecord => makeCartItemRecord(mapper(props))

export const CartItemRecordsCollection = makeListNew<
  CartItemRecord,
  CartItemType
>(CartItemRecord)
