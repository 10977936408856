import React, { Fragment } from 'react'
import cn from 'classnames'

import { CartItemType } from '@monorepo/deprecated/interfaces'
import { CartItem } from './CartItem'
import { Divider } from './Divider'

type PropsType = React.HTMLAttributes<HTMLUListElement> & {
  items: Array<CartItemType>
  removeCartItemHandler(item: CartItemType): void
}

export const CartItemsList: React.FC<PropsType> = ({
  items,
  removeCartItemHandler,
  className,
  ...props
}) => (
  <ul className={cn('dropcart__list', className)} {...props}>
    {items.map((item) => (
      <Fragment key={item.id}>
        <CartItem
          item={item}
          removeCartItemHandler={removeCartItemHandler.bind(null, item)}
        />
        <Divider />
      </Fragment>
    ))}
  </ul>
)
