import { Map } from 'immutable'
import { RequestAction, RequestActionMeta } from 'redux-saga-requests'

import { GoodRecordsCollection } from '@monorepo/deprecated/core/records'

import { CLEAR_CAROUSEL, FETCH_CAROUSEL } from './constants'
import { StateType } from './types'
import { DataRecord } from './records'

export function fetchCarouselAction(name: string): RequestAction {
  return {
    type: FETCH_CAROUSEL,
    request: {
      url: `/carousel/${name}`,
    },
    meta: {
      name,
      cache: 600,
      cacheKey: String(name),
      getDefaultData: (multi: boolean) => Map(),
      getData: (state: any, { data: result, meta: { name } }: any) => {
        const data = state.data || DataRecord()
        try {
          return data.set(name, GoodRecordsCollection(result))
        } catch (error) {
          return data
        }
      },
    },
  }
}

type ClearCarouselActionProps = { name: string }
type ClearCarouselAction = RequestAction & {
  meta: RequestActionMeta & ClearCarouselActionProps
}
export function clearCarouselAction(name: string): ClearCarouselAction {
  return {
    type: CLEAR_CAROUSEL,
    request: false,
    meta: {
      name,
      asMutation: true,
      mutations: {
        [FETCH_CAROUSEL]: {
          updateData(state: StateType, { meta }: ClearCarouselAction) {
            const data = state.data || DataRecord()
            const name = meta.name

            return data.delete(name)
          },
          local: true,
        },
      },
    },
  }
}
