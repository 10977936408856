import { useMemo } from 'react'
import { chain, isEmpty, partition, template } from 'lodash'

import { Catalogs } from '@monorepo/core/config'

import { initialData } from 'App/data/catalogs'

function applyClientId(url: string, clientId: string): string {
  return template(url)({ clientId })
}

export function useCatalogsList() {
  const { clientId, mountPoint } = Catalogs.CatalogsParts

  const catalogs = useMemo(
    () =>
      chain(initialData.catalogsParts)
        .filter('isActive')
        .map(({ url, ...catalog }) => ({
          url: applyClientId(url, String(clientId)),
          mountPoint: `${mountPoint}/${catalog.id}`,
          ...catalog,
        }))
        .sortBy('name')
        .value(),
    [clientId, mountPoint]
  )

  const [commonCatalogs, carCatalogs] = useMemo(
    () => partition(catalogs, (catalog) => isEmpty(catalog.category)),
    [catalogs]
  )

  // const catalogs = chain(initialData)
  //   .filter('isActive')
  //   .map(({ url, ...catalog }) => ({
  //     url: applyClientId(url, String(clientId)),
  //     mountPoint: `${mountPoint}/${catalog.name}`,
  //     ...catalog,
  //   }))
  //   .sortBy('name')
  //   .value()

  return { catalogs, commonCatalogs, carCatalogs }
}
