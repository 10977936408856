import { List, Record, RecordOf } from 'immutable'
import { parseISO } from 'date-fns'

import { BlogPostType } from '@monorepo/deprecated/interfaces'
import { makeListNew } from '../CollectionFactory'

export type BlogPostRecordPropsType = BlogPostType & {}

const defaultValues: BlogPostRecordPropsType = {
  title: '',
  slug: '',
  mainImage: undefined,
  postImage: undefined,
  postThumb: undefined,
  url: '',
  content: '',
  short: '',
  description: '',
  createdAt: new Date(),
  tags: [],
  likesCount: 0,
  comments: [],
  categoryName: '',
  categoryType: '',
}

export type BlogPostRecord = RecordOf<BlogPostRecordPropsType>
export type BlogPostRecordsCollection = List<BlogPostRecord>

const makeBlogPostRecord: Record.Factory<BlogPostRecordPropsType> = Record(
  defaultValues,
  'BlogPostRecord'
)

const mapper = ({
  createdAt,
  ...props
}: Partial<BlogPostType>): Partial<BlogPostRecordPropsType> => ({
  createdAt: createdAt ? parseISO(createdAt.toString()) : new Date(),
  ...props,
  url: `/blog/${props.slug}`,
})

export const BlogPostRecord = (
  props: Partial<BlogPostType> = {}
): BlogPostRecord => makeBlogPostRecord(mapper(props))

export const BlogPostRecordsCollection = makeListNew<
  BlogPostRecord,
  BlogPostType
>(BlogPostRecord)
