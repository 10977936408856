import { useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import {
  postSelector,
  clearPostsAction,
  fetchPostAction,
} from '@monorepo/deprecated/core/features/BlogPost'

export function usePost(slug: string) {
  const dispatch = useDispatch()

  const fetchPost = useCallback(
    bindActionCreators(fetchPostAction, dispatch),
    []
  )

  const clearPosts = useCallback(
    bindActionCreators(clearPostsAction, dispatch),
    []
  )

  const { loading, data: post } = useSelector(postSelector)

  useEffect(() => {
    fetchPost({ slug })
    return () => {
      clearPosts()
    }
  }, [clearPosts, fetchPost, slug])

  return {
    post,
    loading,
  }
}
