import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import chunk from 'lodash/chunk'
import cn from 'classnames'

import { MenuType, MenuItemType } from '@monorepo/deprecated/interfaces'
import { Link } from 'App/Components/Link'

type SubMenuProps = {
  items: MenuType
  cols: 1 | 2 | 3 | 4
  renderItem?(item: MenuItemType): React.ReactNode
}

export const SubMenu: React.FC<SubMenuProps> = ({
  items,
  cols,
  renderItem,
}) => {
  const chunkSize = Math.ceil(items.length / cols)

  return (
    <div className="megamenu departments__megamenu departments__megamenu--size--lg departments__megamenu--open">
      {/* <div className="megamenu__image">
        <img src="images/departments/departments-1.jpg" alt="" />
      </div> */}
      <Row>
        {chunk(items, chunkSize).map((items, idx) => (
          <Col key={idx} xs={12 / cols}>
            <ul
              key={idx}
              className="megamenu__links megamenu-links megamenu-links--root"
            >
              {items.map((item, idx) => (
                <li
                  key={idx}
                  className={cn('megamenu-links__item', {
                    'megamenu-links__item--has-submenu': !!item.menu,
                  })}
                >
                  <Link to={item.url} className="megamenu-links__item-link">
                    {renderItem ? renderItem(item) : item.name}
                  </Link>
                  {item.menu && (
                    <ul className="megamenu-links">
                      {item.menu.map((item, idx) => (
                        <li key={idx} className="megamenu-links__item">
                          <Link
                            to={item.url}
                            className="megamenu-links__item-link"
                          >
                            {renderItem ? renderItem(item) : item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Col>
        ))}
      </Row>
    </div>
  )
}
