import React from 'react'

import { PaymentItem, IPaymentSystem } from './PaymentItem'

type PaymentsListProps = {
  title?: React.ReactNode
  items: IPaymentSystem[]
}

export const PaymentsList: React.FC<PaymentsListProps> = ({ title, items }) => (
  <div className="site-footer__payments d-flex align-items-center">
    {title && <div className="mr-2">{title}:</div>}
    {items.map((item, idx) => (
      <PaymentItem key={idx} item={item} />
    ))}
  </div>
)
