import { Record, RecordOf } from 'immutable'

// import { EntityRecord } from '@monorepo/core/utils/CollectionRecord'
import { GoodRecordsCollection as SuggestionsCollection } from '@monorepo/deprecated/core/records'
import { GoodType } from '@monorepo/deprecated/interfaces'

// import { GoodRecord } from 'core/records'

// export const ReducerRecord = EntityRecord(
//   {
//     query: '',
//     vin: null,
//     // current: null,
//     // entities: GoodRecord.Collection(),
//   } /*,
//   ({ current = undefined, entities }) => ({
//     current: current ? GoodRecord(current) : null,
//     entities: entities ? GoodRecord.Collection(entities) : undefined,
//   })*/
// )

export interface StateType {
  query: string
  current: GoodType | null
  vin: string | null
  suggestions: Array<GoodType>
}

interface StateRecordPropsType extends Omit<StateType, 'suggestions'> {
  suggestions: SuggestionsCollection
}

export const StateRecord = Record<StateRecordPropsType>({
  query: '',
  current: null,
  vin: null,
  suggestions: SuggestionsCollection(),
})

export type StateRecordType = RecordOf<StateRecordPropsType>

export { SuggestionsCollection }
