import React from 'react'

import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { Link } from 'App/Components/Link'

type PropsType = MenuItemType & {}

export const MenuItem: React.FC<PropsType> = (item) => (
  <li>
    <Link to={item.url} external={item.external}>
      {item.name}
    </Link>
  </li>
)
