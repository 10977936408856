import { createElement } from 'react'
import { Loader } from 'App/Components/Loader'
import { configure as configureLazyPage } from '@monorepo/deprecated/core/hoc/lazyPage'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
import {
  faYoutube,
  faFacebookF,
  faTwitter,
  faInstagram,
  faVk,
} from '@fortawesome/free-brands-svg-icons'
// import { withLayout } from 'app/view/Layout'

configureLazyPage({ delay: 200, pageLoader: createElement(Loader) })

library.add(faYoutube, faFacebookF, faTwitter, faInstagram, faVk)
