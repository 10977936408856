import lazyPage from '@monorepo/deprecated/core/hoc/lazyPage'

export default () => [
  {
    path: '/cart',
    component: lazyPage(() => import('App/View/Cart').then(({ Cart }) => Cart)),
    exact: true,
  },
  {
    path: '/cart/order/:orderId',
    component: lazyPage(() =>
      import('App/View/Cart').then(({ NewOrder }) => NewOrder)
    ),
    exact: true,
  },
]
