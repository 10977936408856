import { QueryState } from 'redux-saga-requests'
import { Record, RecordOf } from 'immutable'

import {
  BlogPostRecordsCollection,
  PaginatorRecord,
} from '@monorepo/deprecated/core/records'
import {
  BlogPostType,
  PaginationMetaType,
} from '@monorepo/deprecated/interfaces'

export interface DataType {
  items: Array<BlogPostType>
  meta: PaginationMetaType
}

interface DataRecordType extends Omit<DataType, 'items' | 'meta'> {
  items: BlogPostRecordsCollection
  meta: PaginatorRecord
}

export const DataRecord = Record<DataRecordType>({
  items: BlogPostRecordsCollection(),
  meta: PaginatorRecord(),
})

export type DataRecord = RecordOf<DataRecordType>

export type StateType = QueryState<DataRecord>
