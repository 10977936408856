import { useMemo, useCallback } from 'react'

import { UserProfileType } from '@monorepo/deprecated/interfaces'
import { getUserProfileSchema } from '@monorepo/validations/UserProfileSchema'
import { useCompany } from '../App/useCompany'
import { useUser } from './useUser'

export function useUserProfileForm() {
  const { user, registerUserHandler, updateProfileHandler } = useUser()
  const { salesPoint } = useCompany()

  const initialValues: UserProfileType = useMemo(
    () => ({
      lastName: user.profile.lastName ?? '',
      firstName: user.profile.firstName ?? '',
      middleName: user.profile.middleName ?? '',
      email: user.profile.email ?? '',
      phone: user.profile.phone ?? '',
      address: user.profile.address ?? '',
      markup: user.profile.markup ?? 0,
      isWholesale: user.profile.isWholesale ?? false,
      ...(user.isGuest && {
        office: user.profile.office ?? salesPoint?.[0],
        plainPassword: {
          first: '',
          second: '',
        },
      }),
    }),
    [
      salesPoint,
      user.isGuest,
      user.profile.address,
      user.profile.email,
      user.profile.firstName,
      user.profile.isWholesale,
      user.profile.lastName,
      user.profile.markup,
      user.profile.middleName,
      user.profile.office,
      user.profile.phone,
    ]
  )

  const submitHandler = useCallback(
    (values: UserProfileType) => {
      if (user.isGuest) {
        return registerUserHandler(values)
      } else {
        return updateProfileHandler(values)
      }
    },
    [registerUserHandler, updateProfileHandler, user.isGuest]
  )
  // const validationSchema = useMemo(() => getUserProfileSchema({ user }), [user])
  const validationSchema = getUserProfileSchema({ user })

  return { validationSchema, initialValues, submitHandler }
}
