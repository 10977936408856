/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { MenuType } from '@monorepo/deprecated/interfaces'
import { Logo } from './Logo'
import { Indicators } from './Indicators'
import { Navbar } from './Navbar'
import { TopToolbar } from './TopToolbar'
import { Search } from './Search'

type MainHeaderProps = {
  menu: MenuType
  logoImage: string
}

export const MainHeader: React.FC<MainHeaderProps> = ({ menu, logoImage }) => (
  <header className="site__header sticky-top" style={{ top: -35 }}>
    <div className="header">
      <div className="header__megamenu-area megamenu-area" />
      <TopToolbar />
      <Navbar menu={menu} />
      <Logo logoImage={logoImage} />
      <Search />
      <Indicators />
    </div>
  </header>
)
