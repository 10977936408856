import { createElement, Fragment } from 'react'
import * as Yup from 'yup'

import { OfferType } from '@monorepo/deprecated/interfaces'
import { Currency } from '@monorepo/components/common/Currency'

export const CartItemSchema = Yup.object().shape({
  isSelected: Yup.boolean(),
  offer: Yup.object()
    .label('Предложение')
    .nullable()
    .when('isSelected', (isSelected: boolean, schema: Yup.ObjectSchema) => {
      return isSelected ? schema.required() : schema
    }),
  amount: Yup.number()
    .label('Количество')
    .when(
      ['isSelected', 'offer'],
      (
        isSelected: boolean,
        offer: OfferType | null,
        schema: Yup.NumberSchema
      ) =>
        isSelected
          ? schema
              .test('Количество', 'Товар закончился', () => !!offer)
              .min(offer ? offer.minAmount : 1)
              .max(offer ? offer.amount : Infinity)
              .required()
          : schema
    ),
  price: Yup.number()
    .label('Цена')
    .when(
      ['isSelected', 'offer'],
      (
        isSelected: boolean,
        offer: OfferType | null,
        schema: Yup.NumberSchema
      ) => {
        return isSelected && !!offer
          ? schema
              .min(offer.price, ({ min }) =>
                // TODO: Remove react and currency
                createElement(Fragment, null, [
                  'Цена изменилась, новая цена ',
                  createElement(Currency, {
                    key: Date.now().toString(),
                    value: min,
                  }),
                ])
              )
              .required()
          : schema
      }
    ),
  deliveryTime: Yup.number().label('Срок поставки'),
})
