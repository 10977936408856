import { Action } from 'redux'
import {
  error,
  success,
  RequestAction,
  RequestActionMeta,
} from 'redux-saga-requests'

import { GoodType } from '@monorepo/deprecated/interfaces'
import {
  CLEAR_OFFERS,
  // CLEAR_SUGGESTIONS,
  FETCH_API,
  FETCH_OFFERS,
  FETCH_SOURCE,
  // FETCH_SUGGESTIONS,
  SHOW_MORE_OFFERS,
  HIDE_OFFERS,
} from './constants'
import {
  GoodRecord,
  // GoodRecordsCollection,
  OfferRecordsCollection,
} from '@monorepo/deprecated/core/records'
import { StateRecord, DataRecord } from './records'
import { RemoteFilterType } from './types'
import { createKey } from './utils'

// export function fetchSuggestionsAction(query: string): RequestAction {
//   return {
//     type: FETCH_SUGGESTIONS,
//     request: {
//       url: '/search/goods',
//       params: {
//         number: query,
//         strict: true,
//       },
//     },
//     meta: {
//       // asPromise: true,
//       resetOn: [CLEAR_SUGGESTIONS],
//       // takeLatest: true,
//       cache: 600,
//       cacheKey: query,
//       getData: (_state, action) => {
//         return GoodRecordsCollection(action.data)
//       },
//     },
//   }
// }

// export function clearSuggestionsAction(): Action {
//   return {
//     type: CLEAR_SUGGESTIONS,
//   }
// }

export function fetchSourceAction(filter: RemoteFilterType): RequestAction {
  return {
    type: FETCH_SOURCE,
    request: {
      url: '/v2/search/offers',
      params: filter,
    },
    meta: {
      resetOn: [FETCH_OFFERS, CLEAR_OFFERS],
      abortOn: [FETCH_OFFERS, CLEAR_OFFERS],
      asPromise: true,
      takeLatest: false,
      filter,
      getData(state: StateRecord, action: any) {
        const { good, offers, remote } = action.data
        const filter: RemoteFilterType = action.meta.filter
        const requestParams = {
          brandName: filter.brandName.toLowerCase(),
          number: filter.number.toLowerCase(),
          crosses: filter.crosses,
        }
        const data =
          state.data ||
          DataRecord({
            good: GoodRecord(good),
            remote,
            requestParams,
          })

        return data.update('offers', (offersCollection) =>
          offersCollection.merge(OfferRecordsCollection(offers))
        )
      },
      // getError(state: StateRecord, action) {
      //   console.log(action)
      //   // const data = state.data || DataRecord()

      //   // return data
      // },
    },
  }
}

// export type FetchOffersActionType = RequestAction & {
//   meta: RequestActionMeta & {
//     filter: RemoteFilterType
//   }
// }
export type FetchOffersActionType = Action & {
  payload: RemoteFilterType
}
export function fetchOffersAction(
  filter: RemoteFilterType
): FetchOffersActionType {
  return {
    type: FETCH_OFFERS,
    payload: filter,
  }
}

export function successFetchOffersAction(): RequestAction {
  return {
    type: success(FETCH_OFFERS),
    request: false,
    meta: {
      mutations: {
        [FETCH_SOURCE]: {
          updateData({ data }: StateRecord) {
            return data.set('loaded', true)
          },
          local: true,
        },
      },
    },
  }
}

export function errorFetchOffersAction(): RequestAction {
  return {
    type: error(FETCH_OFFERS),
    request: false,
    meta: {
      mutations: {
        [FETCH_SOURCE]: {
          updateData() {
            return DataRecord({ loaded: true })
          },
          local: true,
        },
      },
    },
  }
}

export type FetchApiActionType = RequestAction & {
  meta: RequestActionMeta & {
    filter: RemoteFilterType
  }
}
export function fetchApiAction(filter: RemoteFilterType): FetchApiActionType {
  return {
    type: FETCH_API,
    request: false,
    meta: {
      filter,
      mutations: {
        [FETCH_SOURCE]: {
          updateData({ data }: StateRecord, { meta }: FetchApiActionType) {
            const key = createKey(meta.filter)
            // return data
            return data.update('apiState', (apiState) =>
              apiState.set(key, 'loading')
            )
          },
          local: true,
        },
      },
    },
  }
}

export type SuccessFetchApiActionType = RequestAction & {
  meta: RequestActionMeta & {
    filter: RemoteFilterType
  }
}
export function successFetchApiAction(
  filter: RemoteFilterType
): SuccessFetchApiActionType {
  return {
    type: success(FETCH_API),
    request: false,
    meta: {
      filter,
      mutations: {
        [FETCH_SOURCE]: {
          updateData({ data }: StateRecord, { meta }: FetchApiActionType) {
            const key = createKey(meta.filter)

            return data
              ? data.update('apiState', (apiState) =>
                  apiState.set(key, 'loaded')
                )
              : data
          },
          local: true,
        },
      },
    },
  }
}

export type ShowMoreOffersActionType = RequestAction & {
  meta: RequestActionMeta & {
    good: GoodType
  }
}
export function showMoreOffersAction(good: GoodType): ShowMoreOffersActionType {
  return {
    type: SHOW_MORE_OFFERS,
    request: false,
    meta: {
      good,
      mutations: {
        [FETCH_SOURCE]: {
          updateData: (
            { data }: StateRecord,
            { meta }: ShowMoreOffersActionType
          ) => {
            return data.setIn(['showState', meta.good.id], 10)
          },
          local: true,
        },
      },
    },
  }
}

export type ShowAllOffersActionType = RequestAction & {
  meta: RequestActionMeta & {
    good: GoodType
  }
}
export function showAllOffersAction(good: GoodType): ShowAllOffersActionType {
  return {
    type: SHOW_MORE_OFFERS,
    request: false,
    meta: {
      good,
      mutations: {
        [FETCH_SOURCE]: {
          updateData: (
            { data }: StateRecord,
            { meta }: ShowAllOffersActionType
          ) => {
            return data.setIn(['showState', meta.good.id], true)
          },
          local: true,
        },
      },
    },
  }
}

export type HideOffersActionType = RequestAction & {
  meta: RequestActionMeta & {
    good: GoodType
  }
}
export function hideOffersAction(good: GoodType): HideOffersActionType {
  return {
    type: HIDE_OFFERS,
    request: false,
    meta: {
      good,
      mutations: {
        [FETCH_SOURCE]: {
          updateData: (
            { data }: StateRecord,
            { meta }: HideOffersActionType
          ) => {
            return data.removeIn(['showState', meta.good.id])
          },
          local: true,
        },
      },
    },
  }
}
