import * as Yup from 'yup'

import { UserType } from '@monorepo/deprecated/interfaces'

// eslint-disable-next-line no-control-regex, no-useless-escape
const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/gim

type GetUserProfileSchemaProps = {
  user: UserType
}

export const getUserProfileSchema = ({ user }: GetUserProfileSchemaProps) =>
  Yup.object().shape({
    // isGuest: Yup.boolean(),
    lastName: Yup.string().label('Фамилия').min(3).max(100).required(),
    firstName: Yup.string().label('Имя').min(3).max(100).required(),
    middleName: Yup.string().label('Отчество').min(0).nullable().max(100),
    address: Yup.string().label('Адрес').min(0).max(255),
    office: Yup.string()
      .label('Офис обслуживания')
      .when([], {
        is: () => user.isGuest,
        then: (schema: Yup.StringSchema) => schema.required(),
      }),
    // office: Yup.string()
    //   .label('Офис обслуживания')
    //   .when('isGuest', {
    //     is: true,
    //     then: (schema: Yup.StringSchema) => schema.required(),
    //   }),
    email: Yup.string()
      .label('E-Mail')
      .email()
      .matches(emailRegex, 'Некорректный адрес email')
      .required(),
    phone: Yup.string().label('Телефон').phoneNumber('ru').required(),
    markup: Yup.number().label('Наценка').min(0),
    // .required()
    plainPassword: Yup.object().shape({
      first: Yup.string()
        .label('Пароль')
        .min(6)
        .matches(
          /[0-9a-zA-ZA-Z]/,
          'Пароль может состоять из символов латинского алфавита или цифр '
        )
        .when([], {
          is: () => user.isGuest,
          then: (schema: Yup.StringSchema) => schema.required(),
        }),
      second: Yup.string()
        .label('Подтверждение пароля')
        .sameAs(Yup.ref('first'), 'Пароли должны совпадать'),
    }),
    // plainPasswordO: Yup.object().shape({
    //   first: Yup.string()
    //     .label('Пароль')
    //     .min(6)
    //     .matches(/[0-9a-zA-ZA-Z]/)
    //     .when('isGuest', {
    //       is: true,
    //       then: (schema: Yup.StringSchema) => schema.required(),
    //     }),
    //   second: Yup.string()
    //     .label('Подтверждение пароля')
    //     .sameAs(Yup.ref('first'), 'Пароли должны совпадать'),
    // }),
  })
